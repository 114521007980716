import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {SearchHeader} from "../Components/SearchHeader";
import CTable from "../Components/CTable";
import {api_header, get, post} from "../utils/HttpClient"
import SelectBox from "../Components/SelectBox";
import {useConfirm} from "material-ui-confirm";
import {delFirmware} from "../request/FirmwareRequest";

export default function Product() {
    const productTypeList = [
        {name: "充电桩", value: 1},
        {name: "电表", value: 2},
        {name: "空开", value: 3},
        {name: "换电柜", value: 4},
        {name: "其他", value: 5}];
    const bizProtocols = [{name: "SH_ORIGIN", value: 1}];
    const techProtocols = [
        {name: "TCP", value: 1},
        {name: "MQTT", value: 2},
        {name: "COAP", value: 3},
        {name: "HTTP", value: 4}];

    const headRow = [
        {name: "编号", key: "id"},
        {name: "产品名称", key: "productName"},
        {name: "产品类型", key: "type", render: row =>{
                return <Typography>{productTypeList.filter(it => it.value === row.type)[0].name}</Typography>
            }},
        {name: "产品备注", key: "note"},
        {name: "设备编码", key: "appId"},
        {
            name: "业务协议", render: (row) =>
                <Typography>{bizProtocols.filter(it => it.value === row.bizProtocol)[0].name}</Typography>

        },
        {
            name: "通讯协议", render: (row) => {
                return techProtocols.filter(it => it.value === row.techProtocol)[0].name
            }
        },
        {
            name: "操作", render: (key) => {
                console.log(key)
                return <ButtonGroup size={"small"} variant="text">
                    <Button onClick={() => delProduct(key.id)}>删除</Button>
                </ButtonGroup>
            }
        }
    ];


    const [saveProductObj, setSaveProductObj] = useState({});

    const [pageData, setPageData] = useState({dataList: [], totalCount: 0});


    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100
    });


    const search = (pageNum, pageSize) => {
        get(`${api_header}/products/page`, {
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(data => setPageData(data.data))
            .then(data => setSaveDialogOpen(false))
            .catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        search(0, 100)
    }, [])


    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false)
    }


    const saveProduct = () => {
        post(`${api_header}/product/insert`, saveProductObj)
            .then(data => setSaveDialogOpen(false))
            .then(() => search())
            .catch(err => {
                console.log(err)
            });

    }

    const confirm = useConfirm();


    const delProduct = (id) => {
        confirm({description: '确定删除当前的产品'})
            .then(() => {
                console.log("卧惦记了确定")
                post(`${api_header}/product/del/${id}`)
                    .then(data => {
                        search()
                    })
                    .catch(err => {
                    })
            })
            .catch(() => { /* ... */
            });
    }


    return (
        <Box>
            <Card variant={"outlined"}>
                <SearchHeader onClickSearch={() => {
                    search()
                }}/>
                <Divider/>
                <Grid container spacing={2} style={{padding: 15}}>
                    <Grid item xs={2}>
                        <TextField id="standard-basic" label="产品名称" variant={"outlined"} size={"small"}
                                   onChange={(evt) => {
                                       setSearchParams({
                                           ...searchParams,
                                           productName: evt.target.value
                                       })
                                   }}/>
                    </Grid>
                    <Grid item xs={2}>
                        <SelectBox nameTag={'name'} valueTag={'value'}
                                   isSearch label={"产品类型"} handleChange={(value) => {
                            setSearchParams({
                                ...searchParams,
                                type: value
                            })
                        }} selectList={productTypeList}/>
                    </Grid>
                </Grid>
            </Card>

            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        isPagination={true}
                        dataRows={pageData.dataList}
                        rowsPerPage={searchParams.pageSize}
                        totalCount={pageData.totalCount}
                        onPageChange={(page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            console.log("修改分页:", evt)
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)

                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() =>
                                                   setSaveDialogOpen(true)}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label="产品名称" variant={"outlined"} size={"small"}
                                       onChange={(evt) => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               productName: evt.target.value
                                           })
                                       }}/>
                            <SelectBox label={"产品类型"}
                                       selectList={productTypeList}
                                       handleChange={(value) => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               type: value
                                           })
                                       }}
                                       nameTag={'name'} valueTag={'value'}/>
                            <TextField id="standard-basic" label="产品编码" variant={"outlined"} size={"small"}
                                       onChange={(evt) => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               appId: evt.target.value
                                           })
                                       }}/>

                            <TextField id="standard-basic" label="备注" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               note: evt.target.value
                                           })
                                       }}/>
                            <SelectBox label={"业务协议"}
                                       selectList={bizProtocols}
                                       handleChange={(value) => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               bizProtocol: value
                                           })
                                       }}
                                       nameTag={'name'}
                                       valueTag={'value'}/>
                            <SelectBox label={"通讯协议"}
                                       selectList={techProtocols}
                                       handleChange={(value) => {
                                           setSaveProductObj({
                                               ...saveProductObj,
                                               techProtocol: value
                                           })
                                       }}
                                       nameTag={'name'}
                                       valueTag={'value'}
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveProduct}>确定</Button>
                </DialogActions>
            </Dialog>

        </Box>

    );

}
