import React, {useEffect, useState} from 'react';
import {Box, Card, Chip, Tab, Tabs, Typography} from "@mui/material";
import {useParams} from 'react-router-dom';
import {a11yProps, TabPanel} from '../Components/TabPane'
import UpgradeInfo from "./DeviceDetail/UpgradeInfo";
import RepairInfo from "./DeviceDetail/RepairInfo";
import StatisticInfo from "./DeviceDetail/StatisticInfo";
import WarnInfo from "./DeviceDetail/WarnInfo";
import {IOTData} from "./DeviceDetail/IotData/IotData";
import RemoteDebugPane from "./DeviceDetail/RemoteDebugPane";
import DeviceDetailInfo from "./DeviceDetail/DetailInfo";
import {getDevice} from "../request/DeviceRequest";
import {useSnackbar} from "notistack";
import OnlineInfo from "./DeviceDetail/OnlineInfo";

export default function DeviceDetail(props) {
    const [mainTabValue, setMainTabValue] = useState(0);
    let {sn} = useParams();

    const handleMainTabValue = (event, newValue) => {
        setMainTabValue(newValue);
    };

    const [deviceDetail, setDeviceDetail] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getDevice(sn).then(data => {
            setDeviceDetail(data.data)
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }, [])


    console.log("props is:", sn);
    return (
        <Box>
            <Card variant={"outlined"} className="p-2 flex flex-wrap">
                    <div className="p-2">编号: <Chip color={"info"} label={deviceDetail['deviceSn']}/></div>
                    <div className="p-2">状态: {renderStatus(deviceDetail['status'])}</div>
                    <div className="p-2">信号: <Chip color={"info"} label={deviceDetail['signal']}/></div>
                    <div className="p-2">温度: <Chip color={"info"} label={parseInt(deviceDetail['temperature'])}/></div>
                    <div className="p-2">卡号: <Chip color={"info"} label={deviceDetail['imsiNo']}/></div>
                    <div className="p-2">类型: <Chip color={"info"} label={deviceDetail['appId']}/></div>
                    <div className="p-2">版本: <Chip color={"info"} label={deviceDetail['versionId']}/></div>




                {/*<Grid container spacing={2}*/}
                {/*      style={{paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 15}}>*/}
                {/*    <Grid item xs={2} style={{display: 'flex', alignItems: "center"}}>*/}
                {/*        <Typography >{deviceDetail['deviceSn']}</Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1} style={{display: 'flex', alignItems: "center"}}>*/}
                {/*        <Typography >{renderStatus(deviceDetail['status'])}</Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1} style={{display: 'flex', alignItems: "center"}}>*/}
                {/*        <Typography >信号:{deviceDetail['signal']}</Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={1} style={{display: 'flex', alignItems: "center"}}>*/}
                {/*        <Typography >温度:{parseInt(deviceDetail['temperature'])}</Typography>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Card>


            <Card variant={"outlined"} sx={{maxWidth: '100%', bgcolor: 'background.paper', marginTop: 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={mainTabValue} onChange={handleMainTabValue} aria-label="basic tabs example" centered>
                        <Tab label="交互数据" {...a11yProps(0)} />
                        <Tab label="设备详情" {...a11yProps(1)} />
                        <Tab label="告警信息" {...a11yProps(2)} />
                        <Tab label="远程调试" {...a11yProps(3)} />
                        <Tab label="统计报表" {...a11yProps(4)} />
                        <Tab label="维修记录" {...a11yProps(5)} />
                        <Tab label="升级记录" {...a11yProps(6)} />
                        <Tab label="离线记录" {...a11yProps(7)} />
                    </Tabs>
                </Box>
                <TabPanel value={mainTabValue} index={1}>
                    <DeviceDetailInfo sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={0}>
                    <IOTData sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={2}>
                    <WarnInfo sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={3}>
                    <RemoteDebugPane sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={4}>
                    <StatisticInfo sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={5}>
                    <RepairInfo sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={6}>
                    <UpgradeInfo sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={7}>
                    <OnlineInfo sn={sn}/>
                </TabPanel>
            </Card>
        </Box>
    )
}


function renderStatus(status) {
    console.log("状态：", status)
    if (status === 1) {
        return <Chip color={"info"} label={'在线'}/>
    } else if (status === 2) {
        return <Chip color={"secondary"} label={'升级中'}/>
    } else if (status === 0 || status == null) {
        return <Chip color={"error"} label={'离线'}/>
    }
}








