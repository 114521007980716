import Box from "@mui/material/Box";
import {Paper, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis, ScatterChart, Scatter} from 'recharts';
import {snOnlineTrend, snPowerTrend, snTemperatureTrend} from "../../request/StatRequest";
import {format, getBeforeDays, getEndTime, getStartTime} from "../../utils/DateUtil";
import DateTimeLinkPicker from "../../Components/DateTimeLinkPicker";
import SelectBox from "../../Components/SelectBox";

export default function StatisticInfo(props) {
    let {sn} = props;


    const [onlineData, setOnlineData] = useState([]);
    const [snTemperatureList, setSnTemperatureList] = useState([])

    useEffect(() => {
        let startTime = format(getStartTime(getBeforeDays(1)))
        let endTime = format(getEndTime(new Date()))
        getSnTemperatureTrend(startTime, endTime)
        getSnPowerTrend(1,startTime, endTime)
        getSnOnlineTrend(startTime, endTime)
    }, [])


    const [searchSnTemplateParam, setSearchSnTemplateParam] = useState({
        startTime: format(getStartTime(getBeforeDays(1))),
        endTime: format(getEndTime(new Date())),
        outletNum: 1
    });

    const [powerData, setPowerData] = useState([]);

    const getSnPowerTrend = (outletNum, startTime, endTime) => {
        snPowerTrend({
            sn: sn,
            outletNum: outletNum,
            startTime: startTime,
            endTime: endTime
        }).then(data => {
            setPowerData(data.data)
        })
    }


    const getSnOnlineTrend = (startTime, endTime) => {
        snOnlineTrend({
            sn: sn,
            startTime: startTime,
            endTime: endTime
        }).then(data => {
            setOnlineData(data.data)
        })
    }

    const getSnTemperatureTrend = (startTime, endTime) => {
        snTemperatureTrend({
            sn: sn,
            startTime: startTime,
            endTime: endTime
        }).then(data => {
            setSnTemperatureList(data.data)
        })
    }

    const sockets = [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8},
        {name: '9', value: 9},
        {name: '10', value: 10},
        {name: '11', value: 12},
        {name: '13', value: 13},
        {name: '14', value: 14},
        {name: '15', value: 15},
        {name: '16', value: 16},
    ];
    return (
        <Box>
            <Stack spacing={2}>
                <DateTimeLinkPicker beforeDays={1}
                                    handleStartTime={(value) => {
                                        getSnTemperatureTrend(value, searchSnTemplateParam.endTime)
                                        getSnPowerTrend(searchSnTemplateParam.outletNum, value, searchSnTemplateParam.endTime)
                                        setSearchSnTemplateParam({
                                            ...searchSnTemplateParam,
                                            startTime: value
                                        })
                                    }}
                                    handleEndTime={(value) => {
                                        getSnTemperatureTrend(searchSnTemplateParam.startTime, value)
                                        getSnPowerTrend(searchSnTemplateParam.outletNum,searchSnTemplateParam.endTime, value)

                                        setSearchSnTemplateParam({
                                            ...searchSnTemplateParam,
                                            endTime: value
                                        })
                                    }
                                    }/>
                <Paper variant={"outlined"}>
                    <LineChart
                        width={1200}
                        height={200}
                        data={snTemperatureList}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="time">
                            <Label value="温度曲线图" offset={-20} position="left"/>
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="temperature" stroke="#8884d8"
                              activeDot={{r: 8}}/>
                    </LineChart>
                </Paper>
                <Paper variant={"outlined"}>
                    <Box style={{padding: 20}}>
                    <SelectBox selectList={sockets} nameTag={'name'} valueTag={'value'} handleChange={value => {
                        setSearchSnTemplateParam({
                            ...searchSnTemplateParam,
                            outletNum: value
                        })
                        getSnPowerTrend(value, searchSnTemplateParam.startTime, searchSnTemplateParam.endTime)
                    }}
                    label={'选择端口'}/>
                    </Box>
                    <LineChart
                        width={1200}
                        height={200}
                        data={powerData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="time">
                            <Label value="电流图" offset={-20} position="left"/>
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="current" stroke="#8884d8"
                              activeDot={{r: 8}}/>
                    </LineChart>
                    <LineChart
                        width={1200}
                        height={200}
                        data={powerData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="time">
                            <Label value="电压图" offset={-20} position="left"/>
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="voltage" stroke="#8884d8"
                              activeDot={{r: 8}}/>

                    </LineChart>
                    <LineChart
                        width={1200}
                        height={200}
                        data={powerData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="time">
                            <Label value="功率图" offset={-20} position="left"/>
                        </XAxis>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>

                        <Line type="monotone" dataKey="power" stroke="#8884d8"
                              activeDot={{r: 8}}/>
                    </LineChart>
                </Paper>

            </Stack>
        </Box>
    )
}
