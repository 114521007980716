import {useParams} from 'react-router-dom'
import {Link} from "react-router-dom";


import React, {useEffect, useState} from "react";
import {Card, Chip, Divider, Grid, Stack, TextField} from "@mui/material";
import CTable from "../Components/CTable";
import {pageUpgradeLog} from '../request/UpgradeRequest'
import {SearchHeader} from "../Components/SearchHeader";
import SelectBox from "../Components/SelectBox";
import EvStationIcon from "@mui/icons-material/EvStation";

export default function UpgradeBatch(props) {

    const {id} = useParams();

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        batchId: id
    })

    const [pageData, setPageData] = useState({});


    const search = (pageNum = 0, pageSize = 100) => {
        pageUpgradeLog({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(it => {
            setPageData({
                dataList: it.data.dataList,
                totalCount: it.data.totalCount,
                pageNum: it.data.pageNum,
                pageSize: it.data.pageSize
            })
        })
    }

    useEffect(() => {
        search()
    }, [])


    const headRow = [
        {
            name: "编号", key: "id"
        },
        {
            name: "设备编号", key: 'deviceSn', render: row =>
                <React.Fragment>
                    <Chip icon={<Link to={`/device/detail/${row.deviceSn}`}><EvStationIcon color={"green"}/></Link>}
                          label={row.deviceSn}/>
                </React.Fragment>
        },
        {
            name: "状态", key: "deviceStatus", render: (row) => {
                switch (row.deviceStatus) {
                    case 0:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                    case 1:
                        return <Chip size={"small"} label={"在线"} color={"primary"}/>
                    case 2:
                        return <Chip size={"small"} label={"升级中"} color={"warning"}/>
                    default:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                }
            }
        },
        {
            name: "固件名称", key: "firmwareName"
        },
        {
            name: "前置版本", key: "preVersionId"
        },
        {
            name: "预期版本", key: "nextVersionId"
        },
       {
            name: "升级状态", key: 'upgradeStatus',
            render: (row) => {
                return row.upgradeStatus ? (<Chip label={'成功'} size={"small"} color={"primary"}/>) :
                    <Chip label={"未成功"} size={"small"} color={"error"}/>
            }
        },
        {
            name: "创建时间", key: "createTime"
        }, {
            name: "固件地址", key: "fwUrl", render: (row) => {
                return <Link href={`${row.fwUrl}`}>下载</Link>
            }
        }, {
            name: "错误码", key: "errorCode"
        }
    ]


    return (
        <React.Fragment>
            <Stack spacing={2}>
            <Card variant={"outlined"}>
                <SearchHeader onClickSearch={() => {
                    search(0, 100)
                }}/>
                <Divider/>
                <Grid container spacing={2} style={{padding: 15}}>
                    <Grid item xs={2}>
                        <TextField sx={{width: 220}} id="standard-basic" label={"设备编号"} variant={"outlined"}
                                   size={"small"}
                                   onChange={(evt) => {
                                       setSearchParams({
                                           ...searchParams,
                                           deviceSn: evt.target.value
                                       })
                                   }}/>
                    </Grid>

                    <Grid item xs={2}>
                        <SelectBox nameTag={"name"} valueTag={"value"} selectList={[
                            {name: '离线', value: 0},
                            {name: '在线', value: 1},
                            {name: '升级中', value: 2},
                        ]} isSearch handleChange={(value) => {
                            setSearchParams({
                                ...searchParams,
                                status: value
                            })
                        }} label={"状态"}/>

                    </Grid>
                    <Grid item xs={2}>
                        <SelectBox nameTag={"name"} valueTag={"value"} selectList={[
                            {name: '未成功', value: 0},
                            {name: '成功', value: 1},
                        ]} isSearch handleChange={(value) => {
                            setSearchParams({
                                ...searchParams,
                                upgradeStatus: value
                            })
                        }} label={"升级状态"}/>
                    </Grid>
                </Grid>
            </Card>

            <CTable
                headRow={headRow}
                dataRows={pageData.dataList}
                isPagination
                totalCount={pageData.totalCount}
                rowsPerPage={searchParams.pageSize}
                page={searchParams.pageNum}
                onPageChange={(evt, page) => {
                    console.log(page)
                    setSearchParams({
                        ...searchParams,
                        pageNum: page
                    })
                    search(page + 1, searchParams.pageSize)
                }}
                onRowsPerPageChange={(evt) => {
                    setSearchParams({
                        ...searchParams,
                        pageSize: evt.target.value
                    })
                    search(searchParams.pageNum, evt.target.value)
                }}
            />
        </Stack>
        </React.Fragment>

    )
}



