import {post, get, api_header} from '../utils/HttpClient'


export function versionPie(params) {
    return get(`${api_header}/stat/versionPie`, params)
}


export function snTemperatureTrend(param) {
    return get(`${api_header}/stat/temperature/sn`, param)
}


export function snPowerTrend(param) {
    return get(`${api_header}/stat/power/sn`, param)
}

export function snOnlineTrend(param) {
    return get(`${api_header}/stat/online/sn`, param)
}


export function allOutletLoose() {
    return get(`${api_header}/stat/allOutletLoose`)
}

export function deviceOutletLoose(sn) {
    return get(`${api_header}/stat/deviceOutletLoose/${sn}`)
}

export function deviceOutletLooseRank(sn) {
    return get(`${api_header}/stat/deviceOutletLooseRank`)
}

export function outlineStat() {
    return get(`${api_header}/stat/onlineStat`)
}
