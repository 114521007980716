import React from "react";
import GlobalRouter from "../Components/GlobalRouter";

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {zhCN} from '@mui/material/locale';
import {SnackbarProvider} from "notistack";
import {LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Slide} from "@mui/material";
import {ConfirmProvider} from "material-ui-confirm";


function App() {
    const theme = createTheme(
        {
            palette: {
                primary: {main: '#1976d2'},
            },
        },
        zhCN,
    );

    return (

        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    TransitionComponent={Slide}>
                    <ConfirmProvider>
                        <GlobalRouter/>
                    </ConfirmProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
