import React, {useState} from 'react'
import {Box, Card, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../Components/TabPane";
import IotTestSample from "./Detail/IotTestSample";
import AllExchangeData from "../DeviceDetail/IotData/AllExchangeData";
import {useParams} from "react-router-dom";

export default function TestDeviceDetail(props) {
    let {sn} = useParams();


    console.log("设备编号:", sn)

    const [mainTabValue, setMainTabValue] = useState(0);
    const handleMainTabValue = (event, newValue) => {
        setMainTabValue(newValue);
    };

    return (
        <Box>
            <Card variant={"outlined"} sx={{maxWidth: '100%', bgcolor: 'background.paper', marginTop: 2}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={mainTabValue} onChange={handleMainTabValue} aria-label="basic tabs example" centered>
                        <Tab label="交互数据" {...a11yProps(0)} />
                        <Tab label="设备详情" {...a11yProps(1)} />
                        <Tab label="远程调试" {...a11yProps(2)} />
                        <Tab label="场景测试" {...a11yProps(3)} />
                        <Tab label="升级任务" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={mainTabValue} index={0}>
                    <AllExchangeData sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={1}>
                </TabPanel>

                <TabPanel value={mainTabValue} index={2}>
                </TabPanel>
                <TabPanel value={mainTabValue} index={3}>
                    <IotTestSample sn={sn}/>
                </TabPanel>
                <TabPanel value={mainTabValue} index={4}>
                </TabPanel>
                <TabPanel value={mainTabValue} index={5}>
                </TabPanel>
                <TabPanel value={mainTabValue} index={6}>
                </TabPanel>
            </Card>
        </Box>
    )
}
