import React, {useEffect, useState} from "react";
import {Box, Button, Chip} from "@mui/material";
import CTable from "../../Components/CTable";
import {onlinePageInfo} from "../../request/DeviceRequest";
import {format, getBeforeDays, getEndTime, getStartTime} from "../../utils/DateUtil";
import DateTimeLinkPicker from "../../Components/DateTimeLinkPicker";

export default function OnlineInfo(props) {

    let {sn} = props;

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0
    });

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        deviceSn: sn,
        startTime: format(getStartTime(getBeforeDays(15))),
        endTime: format(getEndTime(new Date()))
    });

    useEffect(() => {
        search()
    }, [])

    const search = (pageNum = 0, pageSize = 100) => {
        onlinePageInfo({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(it => {
            setPageData({
                dataList: it.data.dataList,
                totalCount: it.data.totalCount
            })
        })
    }

    return (
        <Box>
            <div className={"flex gap-3"}>
                <DateTimeLinkPicker
                    handleStartTime={(value) => {
                        console.log("开始时间111:", value)
                        setSearchParams({
                            ...searchParams,
                            startTime: value
                        })
                    }}
                    handleEndTime={value => {
                        setSearchParams({
                            ...searchParams,
                            endTime: value
                        })
                    }}
                    beforeDays={15}/>
                <Button variant={"contained"} onClick={() => {
                    search()
                }}>搜索</Button>
            </div>
            <div className={"mt-10"}>
                <CTable
                    headRow={[
                        {name: "编号", key: "id"},
                        {
                            name: "状态", key: "cmdId", render: row => {
                                if (row.cmdId === 0) {
                                    return <Chip color={"success"} label={"在线"}/>
                                } else {
                                    return <Chip color={"error"} label={"离线"}/>
                                }
                            }
                        },

                        {name: "创建时间", key: "time"},
                        {
                            name: "ip", key: 'ip'
                        },
                    ]}
                    dataRows={pageData.dataList}
                    totalCount={pageData.totalCount}
                    page={searchParams.pageSize}
                    onPageChange={(evt, page) => {
                        setSearchParams({
                            ...searchParams,
                            pageNum: page
                        })
                    }}
                    onRowsPerPageChange={(evt) => {
                        setSearchParams({
                            ...searchParams,
                            pageSize: evt.target.value
                        })
                    }}
                    isPagination={true}
                />
            </div>
        </Box>
    )
}
