import React, {useState} from "react";
import {DateTimePicker} from "@mui/lab";
import {Box, TextField} from "@mui/material";
import {format, getAfterDays, getBeforeDays, getEndTime, getStartTime} from "../utils/DateUtil";


export default function DateTimeLinkPicker(props) {

    const {handleStartTime, handleEndTime, beforeDays} = props;
    const eStartTime = getStartTime(getBeforeDays(beforeDays));
    const eEndTime = getEndTime(new Date());

    console.log(eStartTime)
    const [startTime, setStartTime] = useState(eStartTime);
    const [endTime, setEndTime] = useState(eEndTime);

    return (
        <Box>
            <Box style={{display: "inline-flex"}}>
                <DateTimePicker
                    ampm={false}
                    size='small'
                    renderInput={(props) => <TextField  size={"small"} {...props} />}
                    label="开始时间"
                    value={startTime}
                    onChange={(newValue) => {
                        setStartTime(newValue)
                        console.log("点击的起始时间:", newValue)
                        handleStartTime(format(newValue))
                        console.log("格式化点击的起始时间:", format(newValue))
                    }}/>
            </Box>
            <Box style={{display: "inline-flex", marginLeft: 10}}>
                <DateTimePicker
                    size='small'
                    ampm={false}
                    renderInput={(props) => <TextField size={"small"} {...props} />}
                    label="结束时间"
                    value={endTime}
                    onChange={(newValue) => {
                        setEndTime(newValue)
                        handleEndTime(format(newValue))
                    }}
                />
            </Box>
        </Box>
    )
}
