import React, {useEffect, useState} from "react";
import {listCmds, sendCmd} from "../../request/CmdRequest";
import CTable from "../../Components/CTable";
import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import SelectBox from "../../Components/SelectBox";
import {useSnackbar} from "notistack";

export default function RemoteDebugPane(props) {

    let {sn} = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const [cmdInfos, setCmdInfos] = useState([]);

    const [dgOpen, setDgOpen] = useState(false);

    const [curCmd, setCurCmd] = useState({});

    const [sendMsg, setSendMsg] = useState({});

    useEffect(() => {
        listCmds({cmdDir: 1}).then(data => {
            setCmdInfos(data.data)
        })
    }, [])

    const send = () => {
        console.log("send", sendMsg, curCmd.cmdId)
        sendCmd({
            cmdId: curCmd.cmdId,
            sn: sn,
            body: sendMsg
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }


    return (
        <React.Fragment>
            <CTable headRow={[
                {name: "命令", key: "cmdId"},
                {name: "命令名", key: "cmdName"},
                {name: "命令描述", key: "cmdDesc"},
                {
                    name: "操作", render: (row) => {
                        return (
                            <ButtonGroup size={"small"} variant="text">
                                <Button onClick={() => {
                                    setDgOpen(true);
                                    setCurCmd(row)
                                }}>配置参数</Button>
                            </ButtonGroup>
                        )
                    }
                }
            ]}
                    dataRows={cmdInfos}/>


            <Dialog open={dgOpen} onClose={() => {
            }} maxWidth={"sm"} fullWidth={"fullWidth"}>
                <DialogTitle>发送({curCmd.cmdId}: {curCmd.cmdDesc})</DialogTitle>
                <DialogContent style={{paddingTop: 15, paddingBottom: 15}}>
                    {curCmd.cmdId === 257 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"插口号"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        outletNum: value.target.value
                                    })
                                }}/>
                                <TextField label={"时间"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        totalTime: value.target.value
                                    })
                                }}/>
                            </Stack>
                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 260 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"插口号"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        outletNum: value.target.value
                                    })
                                }}/>
                                <TextField label={"时间"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        totalTime: value.target.value
                                    })
                                }}/>
                                <TextField label={"充电周期"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        cycleNum: value.target.value
                                    })
                                }}/>
                                <TextField label={"总电量"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        totalEnergy: value.target.value
                                    })
                                }}/>
                            </Stack>

                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 433 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <SelectBox label={"是否强制"} handleChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        isForceReboot: value
                                    })
                                }} selectList={[{name: "强制", value: 1}, {name: "非强制", value: 0}]}/>
                                <SelectBox label={"是否回滚版本"} handleChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        isGoBackVersion: value
                                    })
                                }} selectList={[{name: "强制", value: 1}, {name: "非强制", value: 0}]}/>
                            </Stack>
                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 258 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"插口号"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        outletNum: value.target.value
                                    })
                                }}/>
                            </Stack>

                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 434 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"AppId"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        appId: value.target.value
                                    })
                                }}/>
                            </Stack>

                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 435 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <SelectBox label={"设置蜂鸣器状态"} handleChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        buzzerStatus: value
                                    })
                                }} selectList={[{name: "开启", value: 1}, {name: "关闭", value: 0}]}/>
                            </Stack>

                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 436 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"停止电流阀值"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        thresholdStopCurrent: value.target.value
                                    })
                                }}/>
                                <TextField label={"浮充时间"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        freeChargeTime: value.target.value
                                    })
                                }}/>
                                <SelectBox label={"是否检查插口"} handleChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        isGoBackVersion: value
                                    })
                                }} selectList={[{name: "检查", value: 1}, {name: "不检查", value: 0}]}/>
                            </Stack>

                        </React.Fragment>
                    )}
                    {curCmd.cmdId === 261 && (
                        <React.Fragment>
                            <Stack spacing={2}>
                                <TextField label={"插口号"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        outletNum: value.target.value
                                    })
                                }}/>
                                <SelectBox label={"状态"} handleChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        status: value
                                    })
                                }} nameTag={"name"} valueTag={"value"} selectList={[
                                    {name: "可用", value: 0},
                                    {name: "余额不足", value: 1},
                                    {name: "卡未注册", value: 2},
                                    {name: "卡禁用", value: 3},
                                    {name: "卡在该站点不可用", value: 4},
                                    {name: "设备未绑定", value: 5},
                                    {name: "设备处于维护状态", value: 6},
                                    {name: "插座状态异常", value: 7},
                                    {name: "插座已被占用", value: 8},
                                ]}/>
                                <TextField label={"余额"} variant={"outlined"} size={"small"} onChange={(value) => {
                                    setSendMsg({
                                        ...sendMsg,
                                        balance: value.target.value
                                    })
                                }}/>
                            </Stack>

                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDgOpen(false)}>关闭</Button>
                    <Button onClick={() => {
                        send();
                        setDgOpen(false);
                    }}>发送</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
