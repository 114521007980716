import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import SelectBox from "../Components/SelectBox";
import {SearchHeader} from "../Components/SearchHeader";
import CTable from "../Components/CTable";
import DateTimeLinkPicker from "../Components/DateTimeLinkPicker";
import {pageFault, snPageFault} from "../request/FaultDataRequest";
import {format, getBeforeDays, getEndTime, getStartTime} from "../utils/DateUtil";
import {Link} from "react-router-dom";


export default function FaultData() {

    const faultTypes = [
        {
            "name": "插座超功率",
            "value": 4
        },
        {
            "name": "设备超功率",
            "value": 5
        }, {
            "name": "插座松动",
            "value": 6
        }, {
            "name": "系统错误",
            "value": 7
        }, {
            "name": "超电压",
            "value": 9
        }, {
            "name": "温度超范围",
            "value": 17
        }
    ]
    const [dialogOpen, setDialogOpen] = useState(false);


    const [snFaultPageData, setSnFaultPageData] = useState(
        {
            totalCount: 0,
            dataList: []
        }
    )

    const snHeadRow = [
        {name: '版本', key: "version"},
        {name: '产品编号', key: "appId"},
        {name: '时间', key: "time"},
        {name: '插口', render: row => {
                let data =  JSON.parse(row.data);
                return <Typography>{data.outletNum}</Typography>
            }},
        {name: '总电量', render: row => {
                let data =  JSON.parse(row.data);
                return <Typography>{data.totalEnergy}</Typography>
            }},
        {name: '总时间',render: row => {
                let data =  JSON.parse(row.data);
                return <Typography>{data.totalUseTime}</Typography>
            } },
        {
            name: '故障类型',  render: row => {
                let data =  JSON.parse(row.data);
                switch (data.outletUseResult) {
                    case 4:
                        return <Typography>插座超功率</Typography>
                    case 5:
                        return <Typography>设备超功率</Typography>
                    case 6:
                        return <Typography>插座松动</Typography>
                    case 9:
                        return <Typography>电压超范围</Typography>
                    case 17:
                        return <Typography>温度超范围</Typography>
                    default:
                        return <Typography>未知错误</Typography>
                }

            }
        },

    ]

    const selectSnFault = (sn, pageNum, pageSize) => {
        snPageFault({
            sn: sn,
            pageNum: pageNum,
            pageSize: pageSize,
            faultType: searchParams.faultType != null ? searchParams.faultType : '',
            startTime: searchParams.startTime,
            endTime: searchParams.endTime
        }).then(data => {
            setSnFaultPageData({
                totalCount: data.data.totalCount,
                dataList: data.data.dataList
            })
        }).catch(err => {
            console.log(err)
        })
    }


    const headRow = [
        {
            name: "设备编号", key: "sn", render: row => {
                return <Link to={`/device/detail/${row.sn}`}>{row.sn}</Link>
            }
        }, {
            name: "告警次数", key: "count", render: row => {
                return <Chip onClick={() => {
                    setDialogOpen(true)
                    setSnSearchParam({
                        ...snSearchParam,
                        sn: row.sn
                    })
                    selectSnFault(row.sn, 0, 10)
                }} label={row.count}/>
            }
        }
    ]

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        startTime: format(getStartTime(getBeforeDays(1))),
        endTime: format(getEndTime(new Date()))
    });

    const [pageData, setPageData] = useState({
        totalCount: 0,
        dataList: []
    });

    useEffect(() => {
        search(0, 100);
    }, [])

    const search = (pageNum, pageSize) => {
        pageFault({...searchParams, pageNum: pageNum, pageSize: pageSize})
            .then(data => {
                setPageData({
                    totalCount: data.data.totalCount,
                    dataList: data.data.dataList
                })
            }).catch(err => {
            console.log(err)
        })
    }

    const [snSearchParam, setSnSearchParam] = useState({
        pageNum: 0,
        pageSize: 100,
    })

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <Card variant={"outlined"}>
                    <SearchHeader onClickSearch={() => {
                        search(searchParams.pageNum, searchParams.pageSize)
                    }}/>
                    <Divider/>
                    <Grid container spacing={2} style={{padding: 15}}>
                        <Grid item>
                            <SelectBox selectList={faultTypes} nameTag={"name"} valueTag={"value"} label={"故障类型"}
                                       handleChange={(value) => {
                                           setSearchParams(
                                               {...searchParams, faultType: value}
                                           )
                                       }}/>
                        </Grid>
                        <Grid item>
                            <DateTimeLinkPicker
                                handleStartTime={(value) => {
                                    console.log("开始时间111:", value)
                                    setSearchParams({
                                        ...searchParams,
                                        startTime: value
                                    })

                                }}
                                handleEndTime={value => {
                                    setSearchParams({
                                        ...searchParams,
                                        endTime: value
                                    })
                                }}
                                beforeDays={1}/>
                        </Grid>
                    </Grid>
                </Card>

                <CTable headRow={headRow}
                        dataRows={pageData.dataList}
                        isPagination={true}
                        page={searchParams.pageNum}
                        totalCount={pageData.totalCount}
                        rowsPerPage={searchParams.pageSize}
                        onPageChange={(evt, page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)
                        }}
                />
                <Dialog open={dialogOpen}
                        maxWidth={'lg'}
                fullWidth={true}>
                    <DialogTitle>新增</DialogTitle>
                    <DialogContent>
                        <Box>
                            <CTable headRow={snHeadRow}
                                    dataRows={snFaultPageData.dataList}
                                    isPagination={true}
                                    page={snSearchParam.pageNum}
                                    totalCount={snFaultPageData.totalCount}
                                    rowsPerPage={snSearchParam.pageSize}
                                    onPageChange={(evt, page) => {
                                        setSnSearchParam({
                                            ...searchParams,
                                            pageNum: page
                                        })
                                        selectSnFault(snSearchParam.sn, page + 1, searchParams.pageSize)
                                    }}
                                    onRowsPerPageChange={(evt) => {
                                        setSnSearchParam({
                                            ...searchParams,
                                            pageSize: evt.target.value
                                        })
                                        selectSnFault(snSearchParam.sn, 0, evt.target.value)
                                    }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} color="primary" onClick={() => setDialogOpen(false)}>取消</Button>
                        <Button variant={"outlined"} color="primary" onClick={() => setDialogOpen(false)}>确定</Button>
                    </DialogActions>
                </Dialog>
            </Stack>


        </React.Fragment>
    );
}
