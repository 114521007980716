import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useConfirm} from "material-ui-confirm";
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import {api_header, get, post} from "../../utils/HttpClient";
import {listProducts} from "../../request/ProductRequest";
import {SearchHeader} from "../../Components/SearchHeader";
import SelectBox from "../../Components/SelectBox";
import CTable from "../../Components/CTable";
import {addRepairLog, listFaultType, pageRepairLog} from "../../request/RepairRequest";
import MultiSelectBox from "../../Components/MultiSelectBox";

export default function RepairInfo(props) {
    let {sn} = props;


    const sockets = [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8},
        {name: '9', value: 9},
        {name: '10', value: 10},
        {name: '11', value: 11},
        {name: '12', value: 12},
        {name: '13', value: 13},
        {name: '14', value: 14},
        {name: '15', value: 15},
        {name: '16', value: 16},
    ];

    const [searchParams, setSearchParams] = useState({
        pageSize: 100, pageNum: 1
    });

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);


    const confirm = useConfirm();
    const handleDelete = () => {
        confirm({description: '确定删除当前的数据?'})
            .then(() => {
                console.log("卧惦记了确定")
            })
            .catch(() => { /* ... */
            });
    }

    const headRow = [{
        name: '编号', key: 'id',

    }, {
        name: '返修类型', key: 'faultType',

    }, {
        name: "端口", key: 'outletNums'
    }, {
        name: '现象', key: 'phenomenon'
    }, {
        name: "原因", key: "reason"
    }, {
        name: "解决方式", key: "handleResult"
    }, {
        name: "销售员", key: "salesman"
    }, {
        name: "维修员", key: "repairman"
    }, {
        name: "提交时间", key: "submitTime"
    }, {
        name: "维修时间", key: "repairTime"
    }, {
        name: "状态", key: "status"
    }]

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0

    });



    const [saveRepairLog, setSaveRepairLog] = useState();

    const [faultTypeList, setFaultTypeList] = useState();

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const saveFirmware = () => {
        addRepairLog({...saveRepairLog, sn: sn}).catch(err => {
            console.log(err)
        })

        search(0, searchParams.pageSize)
        setSaveDialogOpen(false)
    }

    useEffect(() => {
        search(0, searchParams.pageSize)
    }, [])

    const search = (pageNum, pageSize) => {
        pageRepairLog({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        })
            .then(data => setPageData({dataList: data.data.dataList, totalCount: data.data.totalCount}))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        listFaultType().then(data => setFaultTypeList(data.data
        )).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <Box>
            <Card variant={"outlined"}>
                <Grid container spacing={2} style={{padding: 15}}>

                    <Grid item xs={2}>
                        <SelectBox nameTag={'typeName'} valueTag={'id'} isSearch={true} label={"返修类型"}
                                   selectList={faultTypeList}
                                   handleChange={(value) => {
                                       setSearchParams({
                                           ...searchParams,
                                           faultType: value
                                       })
                                   }}/>
                    </Grid>

                </Grid>


            </Card>

            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        dataRows={pageData.dataList}
                        rowsPerPage={searchParams.pageSize}
                        totalCount={pageData.totalCount}
                        isPagination={true}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)
                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() => setSaveDialogOpen(true)}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <SelectBox
                                size={"small"}
                                selectList={faultTypeList}
                                nameTag={"typeName"}
                                valueTag={"id"}
                                label={"故障类型"}
                                handleChange={(value) => {
                                    setSaveRepairLog({
                                        ...saveRepairLog,
                                        faultType: value
                                    })
                                }}
                            />

                            <MultiSelectBox
                                size={"small"}
                                selectList={sockets}
                                label={"插口"}
                                nameTag={"name"}
                                valueTag={"value"}
                                handleChange={(value) => {
                                    setSaveRepairLog({
                                        ...saveRepairLog,
                                        outletNums: value
                                    })
                                }}
                            />

                            <TextField id="standard-basic" label="现象" variant={"outlined"} size={"small"} multiline={4}
                                       onChange={(evt) => {
                                           setSaveRepairLog({
                                               ...saveRepairLog,
                                               phenomenon: evt.target.value
                                           })
                                       }}/>


                            <TextField id="standard-basic" label="原因" variant={"outlined"} size={"small"} multiline={4}
                                       onChange={evt => {
                                           setSaveRepairLog({
                                               ...saveRepairLog,
                                               reason: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="解决方式" variant={"outlined"} size={"small"} multiline={4}
                                       onChange={evt => {
                                           setSaveRepairLog({
                                               ...saveRepairLog,
                                               handleResult: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="销售人" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveRepairLog({
                                               ...saveRepairLog,
                                               salesman: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="维修人" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveRepairLog({
                                               ...saveRepairLog,
                                               repairman: evt.target.value
                                           })
                                       }}/>

                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveFirmware}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
