import {Box, Button, Chip} from "@mui/material";
import CTable from "../../Components/CTable";
import React, {useEffect, useState} from "react";
import DateTimeLinkPicker from "../../Components/DateTimeLinkPicker";
import {snPageFault} from "../../request/FaultDataRequest";
import {format, getBeforeDays, getEndTime, getStartTime} from "../../utils/DateUtil";

export default function WarnInfo(props) {
    const {sn} = props

    const [searchParams, setSearchParams] = useState({
        sn: sn,
        pageNum: 0,
        pageSize: 100,
        startTime: format(getStartTime(getBeforeDays(7))),
        endTime: format(getEndTime(new Date()))
    });

    const sockets = [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8},
        {name: '9', value: 9},
        {name: '10', value: 10},
        {name: '11', value: 11},
        {name: '12', value: 12},
        {name: '13', value: 13},
        {name: '14', value: 14},
        {name: '15', value: 15},
        {name: '16', value: 16},
    ];

    const search = (pageNum, pageSize) => {
        snPageFault({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(data => {
            setPageData({
                dataList: data.data.dataList.map(it => {
                    let iti = JSON.parse(it.data.toString())
                    return {
                        ...it,
                        'outletNum': iti['outletNum'],
                        'totalUseTime': iti['totalUseTime'],
                        'totalEnergy': iti['totalEnergy'],
                        'energy': iti['totalEnergy'],
                        'outletUseResult': iti['outletUseResult']
                    }
                }),
                totalCount: data.data.totalCount
            })
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        search(0, 100)
    }, [])

    const headRow = [
        {
            name: "原因", key: "outletUseResult", render: row => {
                switch (row.outletUseResult) {
                    case 6:
                        return <Chip size={"small"} color={"error"} label={"插座松动"}/>
                    case 9:
                        return <Chip size={"small"} color={"error"} label={"电压超范围"}/>
                    case 17:
                        return <Chip size={"small"} color={"error"} label={"温度超范围"}/>
                    case 5:
                        return <Chip size={"small"} color={"error"} label={"设备超功率"}/>
                    case 7:
                        return <Chip size={"small"} color={"error"} label={"保险丝烧断或继电器损坏"}/>
                    case 4:
                        return <Chip size={"small"} color={"error"} label={"插座超功率"}/>
                    default:
                        return <Chip size={"small"} color={"error"} label={"未知错误"}/>
                }
            }
        },
        {
            name: "端口", key: "outletNum", render: row => {
                return <Chip label={row.outletNum}/>
            }
        },
        {name: "总电量", key: "totalEnergy"},
        {name: "总时间", key: "totalUseTime"},

        {name: "时间", key: "time"}
    ]

    const [pageData, setPageData] = useState({
        totalCount: 0,
        dataList: []
    })

    return (
        <Box >

            {/*<Grid item spacing={2}>*/}
            {/*    <SelectBox selectList={sockets} nameTag={'name'} valueTag={'value'}*/}
            {/*               isSearch*/}
            {/*               label={"端口"}*/}
            {/*               handleChange={value => {*/}
            {/*                   console.log("lalala", value)*/}
            {/*                   setSearchParams({*/}
            {/*                       ...searchParams,*/}
            {/*                       outletNum: value*/}
            {/*                   })*/}
            {/*               }}/>*/}
            {/*</Grid>*/}
            <Box className={'flex flex-wrap gap-3'}>
            <DateTimeLinkPicker
                handleStartTime={(value) => {
                    console.log("开始时间111:", value)
                    setSearchParams({
                        ...searchParams,
                        startTime: value
                    })
                }}
                handleEndTime={value => {
                    setSearchParams({
                        ...searchParams,
                        endTime: value
                    })
                }}
                beforeDays={7}/>
            <Button variant={"contained"} color={"primary"} onClick={() => {
                search(0, searchParams.pageSize)
            }}>搜索</Button>
            </Box>
            <Box className="mt-3">
                <CTable headRow={headRow}
                        hasCheckBox={false}
                        dataRows={pageData.dataList}
                        isPagination
                        totalCount={pageData.totalCount}
                        rowsPerPage={searchParams.pageSize}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            console.log(page)
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })

                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value,
                                pageNum: 0
                            })
                            search(searchParams.pageNum, evt.target.value)
                        }}
                />
            </Box>
        </Box>
    )
}
