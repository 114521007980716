import {api_header, get} from "../utils/HttpClient";

export function pageFault(params) {
    return get(`${api_header}/fault/page`, params)
}



export function snPageFault(params) {
    return get(`${api_header}/sn/fault/page`, params)
}
