import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import {SearchHeader} from "../Components/SearchHeader";
import SelectBox from "../Components/SelectBox";
import CTable from "../Components/CTable";
import {api_header, get} from '../utils/HttpClient';
import {listProducts} from '../request/ProductRequest';
import {Link} from 'react-router-dom';
import {useConfirm} from "material-ui-confirm";
import {addFirmware, delFirmware, editFirmware} from "../request/FirmwareRequest";

export default function Firmware() {


    const [searchParams, setSearchParams] = useState({
        pageSize: 100, pageNum: 0
    });

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);


    const confirm = useConfirm();
    const handleDelete = (row) => {
        confirm({description: '确定删除当前的数据? 固件版本将一并删除'})
            .then(() => {
                console.log("卧惦记了确定")
                delFirmware(row.id).then(data => search(0, searchParams.pageSize)).catch(err => {
                    console.log(err)
                })
            })
            .catch(() => { /* ... */
            });
    }

    const headRow = [{
        name: '固件名称', key: 'name',

    }, {
        name: '产品类型', key: 'productName',

    }, {
        name: "备注", key: 'note'
    }, {
        name: '块大小', key: 'blockLen'
    }, {
        name: "创建时间", key: "createTime"
    }, {
        name: "操作", render: (row) => {
            return (
                <ButtonGroup size={"small"} variant="text">
                    <Button onClick={() => handleDelete(row)}>删除</Button>
                    <Button onClick={() => edit(row)}>编辑</Button>
                    <Button><Link to={`/firmware/version/${row.id}`}> 版本列表</Link></Button>
                </ButtonGroup>
            )
        }
    }]

    const edit = (row) => {
        console.log('row', row)
        setSaveDialogOpen(true)
        setSaveFirmwareObj({
            flag: 1,
            id: row.id,
            name: row.name,
            productId: row.productId,
            note: row.note,
            blockLen: row.blockLen
        })

    }


    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0

    });


    const [saveFirmwareObj, setSaveFirmwareObj] = useState({
        flag: 0,
        name: '',
        productId: 0,
        note: '',
        blockLen: 64
    });

    const [productTypeList, setProductTypeList] = useState();

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }


    const saveFirmware = () => {
        if (saveFirmwareObj.flag === 0) {
            addFirmware(saveFirmwareObj)
                .then(data => {
                    search(0, searchParams.pageSize)
                    setSaveDialogOpen(false)
                })
                .catch(reason => {
                    console.log(reason);
                })
        } else {
            editFirmware(saveFirmwareObj)
                .then(data => {
                    search(0, searchParams.pageSize)
                    setSaveDialogOpen(false)
                })
                .catch(err => console.log(err))
        }

    }

    useEffect(() => {
        get(`${api_header}/firmware/page`, searchParams)
            .then(data => setPageData({dataList: data.data.dataList, totalCount: data.data.totalCount}))
            .catch(err => console.log(err))
    }, [])

    const search = (pageNum, pageSize) => {
        get(`${api_header}/firmware/page`, {...searchParams, pageNum: pageNum, pageSize: pageSize})
            .then(data => setPageData({dataList: data.data.dataList, totalCount: data.data.totalCount}))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        listProducts().then(data => setProductTypeList(data.data
        ))
    }, [])

    return (
        <Box>
            <Card variant={"outlined"}>
                <SearchHeader onClickSearch={() => {
                    search(0, searchParams.pageSize)
                }}/>
                <Divider/>
                <Grid container spacing={2} style={{padding: 15}}>

                    <Grid item xs={2}>
                        <SelectBox nameTag={'productName'} valueTag={'id'} isSearch={true} label={"产品类型"}
                                   selectList={productTypeList}
                                   handleChange={(value) => {
                                       setSearchParams({
                                           ...searchParams,
                                           productId: value
                                       })
                                   }}/>
                    </Grid>

                </Grid>


            </Card>

            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        dataRows={pageData.dataList}
                        rowsPerPage={searchParams.pageSize}
                        totalCount={pageData.totalCount}
                        isPagination={true}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)
                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() => {
                                                   setSaveFirmwareObj({
                                                       flag: 0
                                                   })
                                                   setSaveDialogOpen(true)
                                               }}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>{saveFirmwareObj.flag === 1 ? '编辑' : '新增'}</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label="固件名称" variant={"outlined"} size={"small"}
                                       value={saveFirmwareObj.name}
                                       onChange={(evt) => {
                                           setSaveFirmwareObj({
                                               ...saveFirmwareObj,
                                               name: evt.target.value
                                           })
                                       }}/>
                            <SelectBox label={"产品类型"}
                                       nameTag={"productName"}
                                       valueTag={"id"}
                                       selectList={productTypeList}
                                       originValue={saveFirmwareObj.productId}
                                       handleChange={(value) => {
                                           setSaveFirmwareObj({
                                               ...saveFirmwareObj,
                                               productId: value
                                           })
                                       }}
                            />

                            <TextField id="standard-basic" label="备注" variant={"outlined"} size={"small"}
                                       value={saveFirmwareObj.note}
                                       onChange={evt => {
                                           setSaveFirmwareObj({
                                               ...saveFirmwareObj,
                                               note: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="单次发送长度" variant={"outlined"} size={"small"}
                                       value={saveFirmwareObj.blockLen}
                                       onChange={evt => {
                                           setSaveFirmwareObj({
                                               ...saveFirmwareObj,
                                               blockLen: evt.target.value
                                           })
                                       }}/>

                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveFirmware}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
