import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CTable from "../Components/CTable";
import ZDropZone from "../Components/ZDropZone";
import {useParams} from 'react-router-dom';
import {api_header, get} from '../utils/HttpClient'
import {addFirmwareVersion, deleteVersion, editFirmwareVersion, pageFirmwareVersion} from "../request/FirmwareRequest";


export default function FirmwareVersion() {

    let {id} = useParams();


    const search = (pageNum, pageSize) => {
        pageFirmwareVersion({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        })
            .then(data =>
                setPageData({
                    dataList: data.data.dataList,
                    totalCount: data.data.totalCount
                }))
            .catch(err => {
                console.log(err)
            })
    }

    const [searchParams, setSearchParams] = useState({
        pageSize: 100, pageNum: 0, firmwareId: id
    });

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const [pageData, setPageData] = useState({
        dataList: [], totalCount: 0
    })

    const [fwUrl, setFwUrl] = useState('');

    const delVersion = (id) => {
        deleteVersion(id).then(err => {
            console.log(err)
        })
        search(0, 100);
    }

    useEffect(() => {
        search(0, 100)
    }, [])

    useEffect(() => {
        get(`${api_header}/firmware/get/${id}`, {})
            .then(data =>
                setFirmware(data.data)
            ).catch(err => {
            console.log(err)
        })
    }, [])

    const headRow = [{
        name: "版本号", key: "version"
    }, {name: "校验码", key: "checksum"}, {
        name: "包大小", key: "fileSize"
    }, {
        name: "块数目", key: "blockCount"
    }, {
        name: "末位块字节数", key: "lastBlockBytes"
    }, {
        name: "下载地址", key: "url", render: (row) => {
            return <Link href={row.url}>固件地址</Link>
        }
    }, {
        name: "备注", key: "note"
    }, {
        name: "操作", render: (row) => {
            return (
                <ButtonGroup size={"small"} variant="text">
                    <Button onClick={() => {
                        edit(row)
                    }}>编辑</Button>
                    <Button onClick={() => {
                        delVersion(row.id)
                    }}>删除</Button>
                    {/* <ConfirmPopover handleConfirm={}/> */}
                </ButtonGroup>
            )
        }
    }]

    const edit = row => {
        console.log(row)
        setSaveDialogOpen(true)
        setSaveFirmwareVersionObj({
            flag: 1,
            id: row.id,
            firmwareId: row.firmwareId,
            url: row.url,
            content: row.content,
            version: row.version,
            note: row.note,
            fileSize: row.fileSize,
            blockCount: row.blockCount,
            blockLen: row.blockLen,
            lastBlockByte: row.lastBlockByte,
        })
    }


    const [firmware, setFirmware] = useState();

    const [saveFirmwareVersionObj, setSaveFirmwareVersionObj] = useState({
        flag: 0
    });

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const saveFirmwareVersion = () => {
        if (saveFirmwareVersionObj.flag === 0) {
            addFirmwareVersion(
                {
                    ...saveFirmwareVersionObj,
                    url: fwUrl,
                    firmwareId: id
                }).then(data => {
                search(0, searchParams.pageSize)
                setSaveDialogOpen(false);
            }).catch(err => {
                console.log(err)
            })
        } else {
            editFirmwareVersion(saveFirmwareVersionObj).then(data => {
                search(0, searchParams.pageSize)
                setSaveDialogOpen(false)
            }).catch(err => console.log(err))
        }
    }

    return (
        <Box>
            <Card variant={"outlined"}>
                <Grid container spacing={2} style={{padding: 10}}>
                    <Grid item xs={2}>
                        <Typography style={{display: "inline-flex"}}>固件名称: </Typography>
                        <Chip label={firmware != null && firmware.name} style={{display: 'inline-flex'}}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>产品类型: {firmware != null && firmware.productName}</Typography>
                    </Grid>
                </Grid>
            </Card>

            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        dataRows={pageData.dataList}
                        rowsPerPage={searchParams.pageSize}
                        totalCount={pageData.totalCount}
                        isPagination={true}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)
                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() => {
                                                   setSaveFirmwareVersionObj({
                                                       flag: 0
                                                   })
                                                   setSaveDialogOpen(true)
                                               }}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>{saveFirmwareVersionObj.flag === 0 ? '新增' : '编辑'}</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label={"版本号"} variant={"outlined"} size={"small"} value={saveFirmwareVersionObj.version}
                                       onChange={evt => {
                                           setSaveFirmwareVersionObj({
                                               ...saveFirmwareVersionObj,
                                               version: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="备注" variant={"outlined"}
                                       value={saveFirmwareVersionObj.note}
                                       multiline
                                       row={4}
                                       size={"small"}
                                       onChange={evt => {
                                           setSaveFirmwareVersionObj({
                                               ...saveFirmwareVersionObj,
                                               note: evt.target.value
                                           })
                                       }}/>
                            <ZDropZone getUrl={(it) => {
                                setFwUrl(it)
                            }}/>
                            {fwUrl !== '' &&
                            <Typography>
                                <Link href={fwUrl}  color="inherit">
                                    上传成功,点此下载
                                </Link>
                            </Typography>
                            }
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveFirmwareVersion}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>)
}
