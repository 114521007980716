import React, {useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../../Components/TabPane";
import AllExchangeData from "./AllExchangeData";
import {CycleInfo} from "./CycleInfo";

export function IOTData(props) {

    const {sn} = props;

    console.log("iotdata设备编号:", sn)
    const [deviceDataValue, setDeviceDataValue] = useState(0);


    const handleDeviceDataTabValue = (evt, newValue) => {
        setDeviceDataValue(newValue)
    }

    return (
        <Box style={{display: "flex"}}>
            <Tabs value={deviceDataValue}
                  orientation={"vertical"}
                  onChange={handleDeviceDataTabValue}
                  aria-label="basic tabs example"
                  centered
                  sx={{borderColor: 'divider'}}
            >
                <Tab label="全部数据" {...a11yProps(0)} />
                <Tab label="充电数据" {...a11yProps(1)} />

            </Tabs>
            <Box style={{marginTop: -24, width: '100%'}}>
                <TabPanel value={deviceDataValue} index={0}>
                    <AllExchangeData sn={sn}/>
                </TabPanel>
                <TabPanel value={deviceDataValue} index={1}>
                    <CycleInfo sn={sn}/>
                </TabPanel>
            </Box>

        </Box>
    );
}
