import * as React from 'react';
import {Box, Button} from "@mui/material";


export function SearchHeader(props) {
    const {onClickSearch, clear} = props;
    return (
        <div className={'flex justify-between items-center'}>
            <h4>数据检索</h4>
            <Box className={'flex gap-2'}>
            <Button variant="contained" color="primary" onClick={onClickSearch} size={"small"}>搜索</Button>
            </Box>
        </div>
    );

}
