import React, {useEffect, useState} from "react";
import {pageUpgradeLog} from "../../request/UpgradeRequest";
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CTable from "../../Components/CTable";
import ZDropZone from "../../Components/ZDropZone";

export default function UpgradeInfo(props) {
    let {sn} = props;

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0
    });

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        deviceSn: sn
    });

    useEffect(() => {
        search()
    }, [])

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const search = (pageNum = 0, pageSize = 10) => {
        pageUpgradeLog({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(it => {
            setPageData({
                dataList: it.data.dataList,
                totalCount: it.data.totalCount
            })
        })
    }

    return (
        <Box>
            <CTable
                headRow={[
                    {name: "编号", key: "id"},
                    {name: "固件名称", key: "firmwareName"},
                    {name: "升级前版本", key: "preVersionId"},
                    {name: "预期版本", key: "nextVersionId"},
                    {name: "升级后版本", key: "lastVersionId"},
                    {name: "固件地址", key: "fwUrl", render: (row) => {
                            return <Link href={row.fwUrl}>固件下载</Link>
                        }},
                    {name: "升级状态", key: "status"},
                    {name: "错误码", key: "errorCode"},
                    {name: "创建时间", key: "createTime"},
                ]}
                dataRows={pageData.dataList}
                totalCount={pageData.totalCount}
                page={searchParams.pageSize}
                onPageChange={(evt, page) => {
                    setSearchParams({
                        ...searchParams,
                        pageNum: page
                    })
                }}
                onRowsPerPageChange={(evt) => {
                    setSearchParams({
                        ...searchParams,
                        pageSize: evt.target.value
                    })
                }}
                isPagination={true}
                toolbar={() => <Button size={"small"}
                                       color={"primary"}
                                       variant={"contained"}
                                       onClick={() => setSaveDialogOpen(true)}>新增</Button>}
            />


            <Dialog open={saveDialogOpen}
                    onClose={() => setSaveDialogOpen(false)}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label={"版本号"} variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           // setSaveFirmwareVersionObj({
                                           //     ...saveFirmwareVersionObj,
                                           //     version: evt.target.value
                                           // })
                                       }}/>
                            <TextField id="standard-basic" label="备注" variant={"outlined"}
                                       multiline
                                       row={4}
                                       size={"small"}
                                       onChange={evt => {
                                           // setSaveFirmwareVersionObj({
                                           //     ...saveFirmwareVersionObj,
                                           //     note: evt.target.value
                                           // })
                                       }}/>

                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={() => setSaveDialogOpen(false)}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={() => setSaveDialogOpen(false)}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
