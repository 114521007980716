import React from "react";
import { useDropzone } from 'react-dropzone';
import Box from "@mui/material/Box";
import { Button, Card, Chip, Grid, Stack } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { share_host } from "../utils/HttpClient";
import {useSnackbar} from "notistack";

export default function ZDropZone(props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const {getUrl} = props;

    const files = acceptedFiles.map(file => (
        <Grid item key={file.path}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
            <Chip size={"small"} icon={<AttachFileIcon />} label={`${file.path} - ${file.size} bytes`}
            />
            <DeleteIcon style={{ alignItems: "center", marginLeft: 40 }} />
        </Grid>

    ));

    return (
        <Card variant={"outlined"}>
            <section className="container">
                <Stack spacing={2}>
                    <Box {...getRootProps({ className: 'dropzone' })}
                        style={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
                        <input {...getInputProps()} />
                        <Stack spacing={2}>
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <DriveFileMoveIcon fontSize={"large"} />
                            </Box>
                            <p>点击上传或拖拽文件到当前区域上传</p>
                        </Stack>
                    </Box>

                    <Grid container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {files}
                    </Grid>
                    <Button onClick={() => {
                        let formData = new FormData();
                        formData.append('file', acceptedFiles[0]);
                        acceptedFiles.map(file => {
                            fetch(`${share_host}/storage/object/upload`, {
                                method: 'POST',
                                body: formData,
                            }).then(res => res.json())
                                .then(data => {
                                    getUrl(data.data.picUrl)
                                }).catch(err => {
                                    enqueueSnackbar(err.message)
                                })
                        })
                    }}>提交</Button>
                </Stack>
            </section>
        </Card>
    );
}
