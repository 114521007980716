import React, {useEffect, useState} from "react";
import {pageCycleData} from "../../../request/RealDataRequest";
import {Box, Button, TextField} from "@mui/material";
import DateTimeLinkPicker from "../../../Components/DateTimeLinkPicker";
import CTable from "../../../Components/CTable";

export function CycleInfo(props) {

    let {sn} = props;

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        deviceSn: sn,
        cmdId: 169
    });

    const search = (pageNum = 0, pageSize = 10) => {
        pageCycleData({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(data => {
            setPageData({
                dataList: data.data.dataList.map(it => {
                    let iti = JSON.parse(it.data.toString())
                    return {
                        ...it,
                        'current': iti['currentCurrent'],
                        'voltage': iti['currentVoltage'],
                        'power': iti['currentPower'],
                        'energy': iti['totalEnergy'],
                        'outletNum': iti['outletNum'],
                        'cycleNum': iti['cycleNum']
                    }
                }),
                totalCount: data.data.totalCount
            })
        })
    }

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0,
        pageNum: 0,
        pageSize: 100
    });

    useEffect(() => {
        search()
    }, [])


    return (
        <Box>
            <Box className={'flex flex-wrap gap-2'}>
                <TextField id="outlined-basic" label="端口" variant="outlined" size="small"
                           onChange={(evt) => {
                               setSearchParams({
                                   ...searchParams,
                                   outletNum: evt.target.value
                               })
                           }}/>
                <DateTimeLinkPicker beforeDays={7}/>
                <Button variant={"contained"} color={"primary"} onClick={() => search()}> 搜索</Button>
            </Box>
            <Box style={{marginTop: 20}}>
                <CTable
                    headRow={[
                        {name: "ID", key: "id"},

                        {name: "时间", key: "time", render: (row) => row.time.substr(0, 19)},
                        {name: "AppID", key: "appId"},
                        {name: "版本号", key: "version"},
                        {
                            name: "端口", key: "outletNum"
                        },
                        {
                            name: "周期", key: "cycleNum"
                        },
                        {
                            name: "电流", key: "current"
                        },
                        {
                            name: "电压", key: "voltage"
                        },
                        {
                            name: "功率", key: "power"
                        },

                    ]}
                    isPagination
                    page={searchParams.pageNum}
                    dataRows={pageData.dataList}
                    totalCount={pageData.totalCount}
                    rowsPerPage={searchParams.pageSize}
                    onPageChange={(evt, newPage) => {
                        setSearchParams({
                            ...searchParams,
                            pageNum: newPage
                        })
                        search(newPage)
                    }}
                    onRowsPerPageChange={(evt) => {
                        setSearchParams({
                            ...searchParams,
                            pageSize: evt.target.value
                        })
                        search(0, evt.target.value)
                    }}
                />
            </Box>
        </Box>
    )
}
