import React from 'react'
import {Box, Card} from "@mui/material";

export default function IotTestSample() {
    return (
        <Box>
            <Card variant={"outlined"}>
                
            </Card>
        </Box>
    )
}
