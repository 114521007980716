import {Box, Card, Chip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {allDetail, lastSocketStatus} from "../../request/DeviceRequest";
import _ from "lodash";

export default function DeviceDetailInfo(props) {

    let {sn} = props;

    const [deviceInfo, setDeviceInfo] = useState({});

    const [socketStatus, setSocketStatus] = useState([]);
    useEffect(() => {
        allDetail(sn).then(data => {
            setDeviceInfo(data.data)
        }).catch()
        lastSocketStatus(sn).then(data => {
            if (data.data != null) {
                console.log("加班", data.data)
                let statusMap = JSON.parse(data.data.data.toString())
                setSocketStatus(statusMap)
            }
        }).catch()
    }, [])


    return (
        <Box className={'flex flex-row gap-3 '}>
            <DeviceBasicInfo detail={deviceInfo}/>
            <SocketChips socketStatus={socketStatus}/>
            <DeviceMap/>
        </Box>
    );
}


function DeviceBasicInfo(props) {

    const {detail} = props

    console.log("真名", detail)

    return (
        <Card variant={"outlined"} className={'flex-grow-0 p-3 flex flex-col gap-2'}>
            <text className={'font-bold text-lg'}>基本属性</text>
            <text className={'flex justify-between'}><span className={'font-bold text-left'}>名称:</span> <span
                className={'text-right'}>{detail.deviceName}</span></text>
            <text className={'flex justify-between'}><span className={'font-bold text-left'}>编号:</span> <span
                className={'text-right'}>{detail.deviceSn}</span></text>
            <text className={'flex justify-between'}><span className={'font-bold'}>省:  </span> {detail.provinceName}
            </text>
            <text className={'flex justify-between'}><span className={'font-bold'}>市:  </span> {detail.cityName}</text>
            <text className={'flex justify-between'}><span className={'font-bold'}>区:</span> {detail.countyName}</text>
            <text className={'flex justify-between'}><span className={'font-bold'}>小区:</span> {detail.apartmentName}
            </text>
        </Card>
    );
}

function SocketChips(props) {
    let {socketStatus} = props

    console.log("真名插口", socketStatus)
    return (
        <Card variant={"outlined"} className={'flex-grow-1 p-3   gap-2 '}>
            <text className={'font-bold text-lg'}>端口信息</text>
            <Box>
                {render161(socketStatus)}
            </Box>
        </Card>
    );
}

function render161(rowData) {
    let num = parseInt(rowData.outletQty)
    let validStatus = _.filter(rowData, (val, key) => {
        return parseInt(key.slice(12)) <= num
    })
    return _.map(validStatus, (val, key) => {
        if (val === 0) {
            return <Chip color={"default"} size={"small"} label={key + 1}/>
        } else if (val === 1) {
            return <Chip color={"primary"} size={"small"} label={key + 1}/>
        } else {
            return <Chip color={"error"} size={"small"} label={key + 1}/>
        }
    })
}

function DeviceMap() {
    return (
        <Card variant={"outlined"} className={'flex-grow p-3'}>
            <Typography style={{fontWeight: 'bold'}}>地图</Typography>
        </Card>
    );
}








