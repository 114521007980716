import React, {useEffect, useState} from "react";
import CTable from "../../Components/CTable";
import {Box, Button, Card, Chip, Grid} from "@mui/material";
import {pageDevices} from "../../request/DeviceRequest";
import EvStationIcon from "@mui/icons-material/EvStation";
import SelectBox from "../../Components/SelectBox";
import {getTestVersions, upgradeTestDevice} from "../../request/TestUpgradeRequest";
import {useConfirm} from "material-ui-confirm";
import {Link} from "react-router-dom";


export default function TestDevice() {

    const confirm = useConfirm();
    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100
    });

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0
    });

    const [checkedRows, setCheckedRows] = useState([]);


    const [selectedVersion, setSelectedVersion] = useState(0)

    const [versionList, setVersionList] = useState([])


    useEffect(() => {
        search(0, 100)
        getTestVersions().then(data => {
            setVersionList(data.data)
        }).catch(err => console.log(err));
    }, [])

    const search = (pageNum, pageSize) => {
        pageDevices({...searchParams, env: 1, pageNum: pageNum, pageSize: pageSize}).then(data => {
            setPageData({
                dataList: data.data.dataList,
                totalCount: data.data.totalCount
            })
        })
    }

    const headRow = [
        {
            name: "设备编号", key: "deviceSn", render: row =>
                <React.Fragment>
                    <Chip icon={<Link to={`/test/device/detail/${row.deviceSn}`}><EvStationIcon color={"green"}/></Link>}
                          label={row.deviceSn}/>
                </React.Fragment>

        },
        {
            name: "设备名称", key: "deviceName"
        },
        {
            name: "状态", key: "status", render: row => {
                switch (row.status) {
                    case 0:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                    case 1:
                        return <Chip size={"small"} label={"在线"} color={"primary"}/>
                    case 2:
                        return <Chip size={"small"} label={"升级中"} color={"warning"}/>
                    default:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                }
            }
        },
        {name: "产品编码", key: "appId"},
        {
            name: "版本", key: "versionId", render: (row) => {
                return <Chip size={"small"} label={row.versionId} color={"success"}/>
            }
        },
        {
            name: '环境', key: "env", render: row => {
                if (row.env === 1) {
                    return "测试"
                } else{
                    return "正式"
                }
            }
        },
        {name: "物联网卡", key: "imsiNo"},
        {name: "信号强度", key: "signal"},
        {
            name: "温度", key: "temperature", render: row => {
                return row.temperature.toFixed(2)
            }
        },

    ]

    const handleBatchUpgradeClick = () => {
        confirm({description: "确定升级到:" + selectedVersion})
            .then(() => {
                console.log("选中的杭周:", checkedRows)
                let sns = checkedRows.map(it => it.deviceSn);
                console.log("sns:", sns)
                console.log("version:", selectedVersion)

                upgradeTestDevice({
                    deviceSns: sns,
                    versionId: selectedVersion
                }).catch(err => console.log(err))
            })
            .catch(() => console.log("错误"))
    }

    return (
        <Box>

            <Card variant={"outlined"} style={{marginTop: 10}}>
                <Grid container spacing={1} style={{padding: 15}}>
                    <Grid item xs={2}>
                        <SelectBox isSearch nameTag={"version"} valueTag={"id"} selectList={versionList}
                                   handleChange={value => {
                                       setSelectedVersion(value)
                                   }}
                                   label={"固件"}/>

                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => {
                            handleBatchUpgradeClick();
                        }} variant={"contained"}>批量升级</Button>
                    </Grid>


                </Grid>


            </Card>


            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        hasCheckBox={true}
                        handleCheckBoxSelect={(rows) => {
                            console.log('选中的行', rows)
                            setCheckedRows(rows)
                        }}
                        dataRows={pageData.dataList}
                        isPagination
                        totalCount={pageData.totalCount}
                        rowsPerPage={searchParams.pageSize}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            console.log(page)
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })

                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value,
                                pageNum: 0
                            })
                            search(searchParams.pageNum, evt.target.value)
                        }}
                />
            </Box>
        </Box>
    )
}
