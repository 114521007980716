import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonGroup, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    TextField, Typography
} from "@mui/material";
import CTable from "../Components/CTable";
import {addUpgradeBatch, deleteUpdate, pageUpgradeBatch} from '../request/UpgradeRequest';
import {Link as RouteLink} from 'react-router-dom'
import SelectBox from "../Components/SelectBox";
import {listProducts} from "../request/ProductRequest";
import {listFirmware, listVersion} from "../request/FirmwareRequest";
import MultiSelectBox from "../Components/MultiSelectBox";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";

export default function Upgrade() {
    const confirm = useConfirm();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleDeleteConfirm = (row) => {
        confirm({ description: '确定删除' + row.name })
            .then(() => {
                deleteUpdate(row.id).catch((err) => {
                    enqueueSnackbar(err.toString(),{
                        persist: false,
                        variant: 'error',
                    })
                })
            })
            .catch((err) => { enqueueSnackbar(err.toString(), {
                persist: false,
                variant: 'error',
            }) });
    };

    const [searchParams, setSearchParams] = useState({
        pageSize: 100, pageNum: 0
    });

    const [editParams, setEditParams] = useState({});

    const [editDialogOpen, setEditDialogOpen] = useState(false);


    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0,
    });

    const search = (pageNum, pageSize) => {
        pageUpgradeBatch({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        }).then(data => {
            setPageData({
                dataList: data.data.dataList,
                totalCount: data.data.totalCount
            })
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);


    const edit = (row)  => {
        setEditParams(row)
        setEditDialogOpen(true)
    }



    const headRow = [
        {
            name: "批次编号", key: "id"
        },
        {
            name: "批次名称", key: "name"
        },
        {
            name: "产品名称", key: "productName"
        },
        {
            name: "固件名称", key: "fwName"
        },
        {
            name: "初始限制版本", key: "limitInitVersion", render: row  => {
                return row.limitInitVersion.map(it => {
                    return <Chip size={"small"} label={it}/>
                })
            }
        },
        {
            name: "固件版本", key: "nextVersion"
        },
        {
            name: "升级版本", key: "nextVersion"
        },
        {
            name: "固件下载", render: (row) =>
                <Link href={row.url}>下载</Link>
        },
        {
            name: "批次备注", key: 'note'
        },

        {
            name: "创建时间", key: "createTime"
        },
        {
            name: "操作", render: (row) => {
                return (
                    <ButtonGroup size={"small"} variant="text">
                        <Button onClick={() =>handleDeleteConfirm(row)}>删除</Button>
                        <Button>升级日志</Button>
                        <Button><RouteLink to={`/upgrade/plan/sub/${row.id}`}>详情</RouteLink></Button>
                    </ButtonGroup>
                )
            }
        }]


    const [firmwareList, setFirmwareList] = useState([])

    const [productTypeList, setProductTypeList] = useState([]);

    useEffect(() => {
        listProducts().then(data => setProductTypeList(data.data
        ))
    }, [])


    const getFwList = (value) => {
        listFirmware(value).then(data => {
            setFirmwareList(data.data)
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }

    const [versionList, setVersionList] = useState([])


    const getFwVersionList = (value) => {
        listVersion(value).then(data => {
            setVersionList(data.data)
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }


    useEffect(() => {
        search(0, 100)
    }, [])

    const [savePlanObj, setSavePlanObj] = useState({});


    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const saveBatch = () => {
        addUpgradeBatch(savePlanObj).catch(err => {
           enqueueSnackbar(err.message)
        })
        search(0, searchParams.pageSize)
        setSaveDialogOpen(false)
    }

    return (
        <Box>

            <Box style={{marginTop: 10}}>
                <CTable headRow={headRow}
                        isPagination={true}
                        dataRows={pageData.dataList}
                        rowsPerPage={pageData.pageSize}
                        page={searchParams.pageNum}
                        totalCount={pageData.totalCount}
                        onPageChange={(evt, page) => {
                            console.log(page)
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            console.log(evt.target.value)
                            setSearchParams({
                                ...searchParams,
                                pageNum: 0,
                                pageSize: evt.target.value
                            })
                            search(1, evt.target.value)
                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() => setSaveDialogOpen(true)}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={4}>
                            <TextField
                                sx={{width: 220}}
                                id="standard-basic"
                                label="计划名称"
                                variant={"outlined"}
                                size={"small"}
                                onChange={evt => {
                                    setSavePlanObj({
                                        ...savePlanObj,
                                        name: evt.target.value
                                    })
                                }}/>

                            <SelectBox nameTag={'productName'} valueTag={'id'} selectList={productTypeList}
                                       label={"选择产品"} handleChange={value => {
                                setSavePlanObj({
                                    ...savePlanObj,
                                    productId: value
                                })
                                getFwList(value)
                            }}/>
                            <SelectBox nameTag={'name'} valueTag={'id'} selectList={firmwareList} label={"选择固件类型"}
                                       handleChange={value => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               fwId: value
                                           })
                                           getFwVersionList(value)

                                       }}/>

                            <MultiSelectBox label={"请选择初始版本"}
                                            nameTag={"version"}
                                            valueTag={"version"}
                                            selectList={versionList}
                                            handleChange={(value) => {
                                                setSavePlanObj({
                                                    ...savePlanObj,
                                                    limitInitVersion: value
                                                })
                                            }}/>


                            <SelectBox nameTag={'version'} valueTag={'id'} selectList={versionList} label={"选择固件版本"}
                                       handleChange={value => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               nextVersionId: value
                                           })
                                       }}/>

                            <TextField id="standard-basic"
                                       sx={{width: 220}}

                                       multiline
                                       rows={4}
                                       label="备注"
                                       variant={"outlined"}
                                       size={"small"}
                                       onChange={evt => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               note: evt.target.value
                                           })
                                       }}/>

                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveBatch}>确定</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}} >
                    <Box>
                        <Stack spacing={4}>
                            <TextField
                                sx={{width: 220}}
                                id="standard-basic"
                                label="计划名称"
                                variant={"outlined"}
                                size={"small"}
                                value={editParams.name}
                                onChange={evt => {
                                    setSavePlanObj({
                                        ...editParams,
                                        name: evt.target.value
                                    })
                                }}/>

                            <SelectBox nameTag={'productName'} valueTag={'id'} selectList={productTypeList}
                                       label={"选择产品"} originValue={editParams.productId} handleChange={value => {
                                setSavePlanObj({
                                    ...editParams,
                                    productId: value
                                })
                                getFwList(value)
                            }}/>
                            <SelectBox nameTag={'name'} valueTag={'id'} originValue={editParams.fwId} selectList={firmwareList} label={"选择固件类型"}
                                       handleChange={value => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               fwId: value
                                           })
                                           getFwVersionList(value)

                                       }}/>

                            <MultiSelectBox label={"请选择初始版本"}
                                            nameTag={"version"}
                                            valueTag={"version"}
                                            selectList={versionList}
                                            originValues={editParams.limitInitVersion}
                                            handleChange={(value) => {
                                                setSavePlanObj({
                                                    ...savePlanObj,
                                                    limitInitVersion: value
                                                })
                                            }}/>


                            <SelectBox nameTag={'version'} valueTag={'id'} originValue={editParams.nextVersionId} selectList={versionList} label={"选择固件版本"}
                                       handleChange={value => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               nextVersionId: value
                                           })
                                       }}/>

                            <TextField id="standard-basic"
                                       sx={{width: 220}}

                                       multiline
                                       rows={4}
                                       label="备注"
                                       value={editParams.note}
                                       variant={"outlined"}
                                       size={"small"}
                                       onChange={evt => {
                                           setSavePlanObj({
                                               ...savePlanObj,
                                               note: evt.target.value
                                           })
                                       }}/>

                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveBatch}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>)
}



