import {Card, Divider, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import _ from 'lodash';

export default function KV(props) {

    const {content, kRender, vRender} = props;



    return (
        <Stack spacing={1} style={{width: "70%"}}>
            {_.map(content, (val, key) =>
                <Grid container spacing={1}>
                    <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end"}}>
                        <Typography>{kRender != null ? kRender(key) : key}:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end"}}>
                        <Typography>{vRender != null ? vRender(val) : val}</Typography>
                    </Grid>
                </Grid>
            )}
        </Stack>
    )
}
