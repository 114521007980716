import React, {useEffect, useState} from "react";
import {addFirmwareVersion, deleteVersion} from "../../request/FirmwareRequest";
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CTable from "../../Components/CTable";
import ZDropZone from "../../Components/ZDropZone";
import {addTestVersion, testVersionDelete, testVersionPage} from "../../request/TestUpgradeRequest";
import {useConfirm} from "material-ui-confirm";

export default function TestFirmware() {
    const confirm = useConfirm();

    const [searchParams, setSearchParams] = useState({
        pageSize: 100, pageNum: 0
    });

    const [pageData, setPageData] = useState({
        dataList: [], totalCount: 0
    })

    const search = (pageNum, pageSize) => {
        testVersionPage({
            ...searchParams,
            pageNum: pageNum,
            pageSize: pageSize
        })
            .then(data =>
                setPageData({
                    dataList: data.data.dataList,
                    totalCount: data.data.totalCount
                }))
            .catch(err => {
                console.log(err)
            })
    }


    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const [fwUrl, setFwUrl] = useState('');

    const delVersion = (row) => {
        confirm({description: "确定删除当前版本" + row.version}).then(() => {
            testVersionDelete(row.id).catch(err => console.log(err))
            search(0, 100);
        }).catch(() => {console.log("错误")})
    }

    useEffect(() => {
        search(0, 100)
    }, [])


    const headRow = [{
        name: "版本号", key: "version"
    }, {name: "校验码", key: "checksum"}, {
        name: "包大小", key: "fileSize"
    }, {
        name: "块数目", key: "blockCount"
    }, {
        name: "末位块字节数", key: "lastBlockBytes"
    },{
        name: "块size", key: "blockLen"
    }, {
        name: "下载地址", key: "url", render: (row) => {
            return <Link href={row.url}>固件地址</Link>
        }
    },{
        name: "备注", key: "note"
    }, {
        name: "操作", render: (row) => {
            return (
                <ButtonGroup size={"small"} variant="text">
                    <Button>编辑</Button>
                    <Button onClick={() => {
                        delVersion(row)
                    }}>删除</Button>
                    {/* <ConfirmPopover handleConfirm={}/> */}
                </ButtonGroup>
            )
        }
    }]

    const [saveFirmwareVersionObj, setSaveFirmwareVersionObj] = useState();

    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false);
    }

    const saveFirmwareVersion = () => {
        addTestVersion(
            {
                ...saveFirmwareVersionObj,
                url: fwUrl,
            }).catch(err => {
            console.log(err)
        })
        search(0, searchParams.pageSize)
        setSaveDialogOpen(false);
    }

    return (
        <Box>


            <Box className={"mt-10"}>
                <CTable headRow={headRow}
                        dataRows={pageData.dataList}
                        rowsPerPage={searchParams.pageSize}
                        totalCount={pageData.totalCount}
                        isPagination={true}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })
                            search(page, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value
                            })
                            search(0, evt.target.value)
                        }}
                        toolbar={() => <Button size={"small"}
                                               color={"primary"}
                                               variant={"contained"}
                                               onClick={() => setSaveDialogOpen(true)}>新增</Button>}/>
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label={"版本号"} variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveFirmwareVersionObj({
                                               ...saveFirmwareVersionObj,
                                               version: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="备注" variant={"outlined"}
                                       multiline
                                       row={4}
                                       size={"small"}
                                       onChange={evt => {
                                           setSaveFirmwareVersionObj({
                                               ...saveFirmwareVersionObj,
                                               note: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label={"单个块长度"} variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveFirmwareVersionObj({
                                               ...saveFirmwareVersionObj,
                                               blockLen: evt.target.value
                                           })
                                       }}/>
                            <ZDropZone getUrl={(it) => {
                                setFwUrl(it)
                            }}/>
                            {fwUrl !== '' &&
                            <Typography>
                                <Link href={fwUrl} color="inherit">
                                    上传成功,点此下载
                                </Link>
                            </Typography>
                            }
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={saveFirmwareVersion}>确定</Button>
                </DialogActions>
            </Dialog>
        </Box>)
}
