import {api_header, get, post} from "../utils/HttpClient";

export function listCmds(params={}) {
    return get(`${api_header}/cmdInfo/list`, params)
}


export function listCmdsSimple(params={}) {
    return get(`${api_header}/cmdInfo/list/simple`, params)
}

export function listMqttUrl(params={}) {
    return get(`${api_header}/iotDevice/listUrl`, params)
}


export function sendReboot(params) {
    return post(`${api_header}/iotDevice/reboot`, params)
}


export function sendCmd(params) {
    return post(`${api_header}/cmdInfo/cmd/send`, params)
}


export function sendConfigAppId(params) {
    return post(`${api_header}/cmd/setAppId`, params)

}

export function sendConfigBuzzer(params) {
    return post(`${api_header}/cmd/setBuzzer`, params)

}

export function sendReboots(params) {
    return post(`${api_header}/cmd/reboot`, params)

}

export function sendMqttUrl(params) {
    return post(`${api_header}/iotDevice/cmd/setMqttUrl`, params)

}
