import {api_header, get} from "../utils/HttpClient";

export  function pageIotData(searchParams={}) {
    return get(`${api_header}/iotDevice/deviceDataPage`, searchParams)
}


export function pageCycleData(searchParams) {
    return get(`${api_header}/iotDevice/deviceCycleDataPage`, searchParams)
}

