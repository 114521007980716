export function getBeforeDays(days) {
    let date = new Date();
    return new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
}

export function getAfterDays(days) {
    let date = new Date();
    return new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
}


export function getEndTime(date) {
    return new Date(date.setHours(23, 59, 59, 999))
}


export function getStartTime(date) {
    return new Date(date.setHours(0, 0, 0, 0))
}


export function format(dt) {
     return `${
        dt.getFullYear().toString().padStart(4, '0')}-${
         (dt.getMonth()+1).toString().padStart(2, '0')}-${
         dt.getDate().toString().padStart(2, '0')} ${
        dt.getHours().toString().padStart(2, '0')}:${
        dt.getMinutes().toString().padStart(2, '0')}:${
        dt.getSeconds().toString().padStart(2, '0')}`
}
