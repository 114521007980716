import React, {useState, useRef, useEffect} from 'react'
import {
    APILoader,
    Map,
    MassMarks
} from "@uiw/react-amap";
import {getDeviceMap} from "../request/DeviceRequest";
import {useSnackbar} from "notistack";

export default function DeviceMap() {
    const [show, setShow] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mapData, setMapData] = useState([])
    const map = useRef()
    const marker = useRef()
    useEffect(() => {
        getDeviceMap().then(data => {
            setMapData(data.data.filter(it => it.axisX != null).map(it => {
               return  {lnglat:[it.axisY,it.axisX],name:it.deviceSn,style:2}
            }))
        }).catch(err => {
            enqueueSnackbar("获取地图信息失败", {
                persist: false,
                variant: 'error',
            })
        })
    }, [])

    return (
        <APILoader akay="a7a90e05a37d3f6bf76d4a9032fc9129">

        <div  className={"w-full h-screen"}>
            <Map zoom={4}>

                <MassMarks
                    visiable={show}
                    data={mapData}
                    onMouseMove={(evn) => {
                        if (!map.current) {
                            map.current = evn.target.getMap();
                            if (!marker.current) {
                                marker.current = new window.AMap.Marker({ content: ' ', map: map.current });
                            }
                        }
                        if (marker.current) {
                            marker.current.setPosition(evn.data.lnglat);
                            marker.current.setLabel({content: evn.data.name})
                        }
                    }}
                />
            </Map>
        </div>
        </APILoader>
    )


}
