import React  from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import GlobalLayout from "./Layout";
import Dashboard from "../pages/Dashboard";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {Breadcrumbs} from "@mui/material";




export default function GlobalRouter() {

    return (
        <Router>
            <GlobalLayout/>
        </Router>
    )

}
