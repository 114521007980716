import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import CTable from "./CTable";

SelectBox.propTypes = {
    size: PropTypes.string,
    selectList: PropTypes.array,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    isSearch: PropTypes.bool,
    nameTag: PropTypes.string,
    valueTag: PropTypes.string,
    originValue: PropTypes.string,
}

export default function SelectBox(props) {


    const {
        size,
        selectList,
        handleChange,
        label,
        isSearch,
        nameTag,
        valueTag,
        originValue
    } = props;




    const [boxValue, setBoxValue] = useState('');


    useEffect(() => {
        if (originValue != null) {
            setBoxValue(originValue)
        }
    }, [])



    let isSearchCopy = isSearch || false;



    const handleInnerChange = (event) => {

        let value = event.target.value
        console.log(value)
        setBoxValue(event.target.value)
        let list = selectList.filter(it => it[valueTag] === value)
        console.log('列表', list)
        if (list != null && list.length > 0) {
            console.log("列表1:", list[0])
            handleChange(list[0][valueTag])
        } else {
            handleChange('')
        }
    }


    return (
        <FormControl fullWidth maxWidth={"lg"} sx={{  width: 220 }} size={size == null ? "small" : size}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={boxValue}
                label={label}
                onChange={handleInnerChange}
            >
                {isSearchCopy && <MenuItem value={''}>全部</MenuItem>}
                {selectList != null && selectList.map(it =>
                    <MenuItem value={it[valueTag]}>{it[nameTag]}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
