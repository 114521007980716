import {api_header, get} from "../utils/HttpClient";

export function pageDevices(params={}) {
    return get(`${api_header}/iotDevice/page`, params)
}
export function listDevice(params={}) {
    return get(`${api_header}/iotDevice/list`, params)
}

export function getDevice(sn) {
    return get(`${api_header}/iotDevice/get/${sn}`)
}

export function getDeviceMap() {
    return get(`${api_header}/iotDevice/map`)
}


export function onlinePageInfo(params) {
    return get(`${api_header}/iotDevice/deviceOnlineInfoPage`, params)
}

export function allDetail(sn) {
    return get(`${api_header}/iotDevice/allDetail/${sn}`)
}


export function lastSocketStatus(sn) {
    return get(`${api_header}/iotDevice/lastSocketStatus/${sn}`)
}

export function ownerList() {
    return get(`${api_header}/iotDevice/ownerList`)
}

export function listProvinces() {
    return get(`${api_header}/iotDevice/provinceList`)
}

export function listCities() {
    return get(`${api_header}/iotDevice/cityList`)
}

export function listCounties() {
    return get(`${api_header}/iotDevice/countyList`)
}

export function listApartments() {
    return get(`${api_header}/iotDevice/apartmentList`)
}

export function listOwners() {
    return get(`${api_header}/iotDevice/ownerList`)
}

export function listVersion() {
    return get(`${api_header}/iotDevice/versionList`)
}

