import {post, get, api_header} from '../utils/HttpClient'




export function getTestVersions() {
    return get(`${api_header}/testVersion/simple/list`)

}

export function addTestVersion(params) {
    return post(`${api_header}/testVersion/add`,params)
}
export function testVersionPage(params) {
    return get(`${api_header}/testVersion/page`,params)
}

export function testVersionDelete(id) {
    return post(`${api_header}/testVersion/delete/${id}`)
}




export function upgradeTestDevice(params) {
    return post(`${api_header}/upgrade/testDevice`, params)
}
