import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    TextField
} from "@mui/material";
import CTable from "../Components/CTable";
import {addUpgradeBatch, pageUpgradeBatch} from '../request/UpgradeRequest';
import {Link as RouteLink} from 'react-router-dom'
import SelectBox from "../Components/SelectBox";
import {listProducts} from "../request/ProductRequest";
import {listFirmware, listVersion} from "../request/FirmwareRequest";
import MultiSelectBox from "../Components/MultiSelectBox";
import Typography from "@mui/material/Typography";

export default function RepairData() {


    return (
        <React.Fragment>
            <Typography>年轻</Typography>
        </React.Fragment>
    )

}



