import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup, Snackbar,
    Stack,
    TextField
} from "@mui/material";
import {SearchHeader} from "../Components/SearchHeader";
import SelectBox from "../Components/SelectBox";
import EvStationIcon from '@mui/icons-material/EvStation';
import Radio from '@mui/material/Radio';
import {listProducts} from '../request/ProductRequest'
import {
    listApartments,
    listCities,
    listCounties,
    listOwners,
    listProvinces, listVersion,
    pageDevices
} from "../request/DeviceRequest";
import {listUpgradeBatch, upgrade} from '../request/UpgradeRequest'
import {listMqttUrl, sendConfigAppId, sendConfigBuzzer, sendMqttUrl, sendReboots} from "../request/CmdRequest";
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import CTable from "../Components/CTable";
import Alert from "@mui/material/Alert";

export default function Device() {

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const checkUpgradeSns = () => {
        enqueueSnackbar('升级设备不能为空', {
            persist: false,
            variant: 'error',
        });
    };

    const [apartmentList, setApartmentList] = useState([])

    const [cityList, setCityList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    const [countyList, setCountyList] = useState([])
    const [versionList, setVersionList] = useState([])
    const [ownerList, setOwnerList] = useState([])

    const sendAlert = (err) => {
        enqueueSnackbar(err.message, {
            persist: false,
            variant: 'error',
        });
    };

    const checkUpgradeVersion = () => {
        enqueueSnackbar('升级计划不能为空', {
            persist: false,
            variant: 'error',
        });
    };


    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100
    });

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0
    });

    const getApartments = () => {
        listApartments().then(res => {
            setApartmentList(res.data.map(it => {
                return {label: it.name, value: it.code}
            }))
        })
    }

    const getProvinces = () => {
        listProvinces().then(res => {
            setProvinceList(res.data.map(it => {
                return {label: it.name, value: it.code}
            }))
        })
    }


    const getCounties = () => {
        listCounties().then(res => {
            setCountyList(res.data.map(it => {
                return {label: it.name, value: it.code}
            }))
        })
    }

    const getVersions = () => {
        listVersion().then(res => {
            setVersionList(res.data.map(it => {
                return {label: it, value: it}
            }))
        })
    }

    const getCities = () => {
        listCities().then(res => {
            setCityList(res.data.map(it => {
                return {label: it.name, value: it.code}
            }))
        })
    }

    const getOwners = () => {
        listOwners().then(res => {
            setOwnerList(res.data.map(it => {
                return {label: it.username, value: it.id}
            }))
        })
    }


    useEffect(() => {
        getApartments()
        getCities()
        getProvinces()
        getCounties()
        getOwners()
        getVersions()
    }, [])

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const [saveDeviceObj, setSaveDeviceObj] = useState({});


    const [productTypeList, setProductTypeList] = useState([]);
    const [mqttUrlTypeList, setMqttUrlTypeList] = useState([]);


    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

    const [upgradeSns, setUpgradeSns] = useState([]);


    const [checkedRows, setCheckedRows] = useState([]);

    const [configAppIdDialogOpen, setConfigAppIdDialogOpen] = useState(false);
    const [restartDialogOpen, setRestartDialogOpen] = useState(false);
    const [configBuzzerDialogOpen, setConfigBuzzerDialogOpen] = useState(false);
    const [mqttUrlDialogOpen, setMqttUrlDialogOpen] = useState(false);


    const handleSaveDialogClose = () => {
        setSaveDialogOpen(false)
    }


    useEffect(() => {
        listProducts().then(data => setProductTypeList(data.data
        ))
    }, [])

    useEffect(() => {
        listMqttUrl().then(data => setMqttUrlTypeList(data.data
        ))
    }, [])

    const [upgradeBatches, setUpgradeBatches] = useState([])

    const searchUpgradeBatch = (productId) => {
        listUpgradeBatch({productId: productId}).then(data => {
            setUpgradeBatches(data.data)
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }

    const [pendingAppId, setPendingAppId] = useState(0);
    const [pendingMqttUrl, setPendingMqttUrl] = useState('');

    useEffect(() => {
        search(0, 100)
    }, [])

    const search = (pageNum, pageSize) => {
        pageDevices({...searchParams, pageNum: pageNum, pageSize: pageSize}).then(data => {
            setPageData({
                dataList: data.data.dataList,
                totalCount: data.data.totalCount
            })
        })
    }

    const [rebootProps, setRebootProps] = useState({
        isForceReboot: 0,
        isGoBackVersion: 0
    })

    const headRow = [
        {
            name: "设备编号", key: "deviceSn", render: row =>
                <React.Fragment>
                    <Chip icon={<Link to={`/device/detail/${row.deviceSn}`}><EvStationIcon color={"green"}/></Link>}
                          label={row.deviceSn}/>
                </React.Fragment>

        },
        {
            name: "设备名称", key: "deviceName"
        },
        {
            name: "状态", key: "status", render: row => {
                switch (row.status) {
                    case 0:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                    case 1:
                        return <Chip size={"small"} label={"在线"} color={"primary"}/>
                    case 2:
                        return <Chip size={"small"} label={"升级中"} color={"warning"}/>
                    default:
                        return <Chip size={"small"} label={"离线"} color={"error"}/>
                }
            }
        },
        {name: "产品编码", key: "appId"},
        {
            name: "版本", key: "versionId", render: (row) => {
                return <Chip size={"small"} label={row.versionId} color={"success"}/>
            }
        },
        {name: "省", key: 'provinceName'},
        {name: "市", key: 'cityName'},
        {name: "区", key: 'countyName'},
        {name: "小区", key: "apartmentName"},
        {name: "地址", key: "address"},
        {name: "拥有人", key: "username"},
        {name: "物联网卡", key: "imsiNo"},
        {name: "信号强度", key: "signal"},
        {
            name: "温度", key: "temperature", render: row => {
                return row.temperature.toFixed(2)
            }
        },
        {name: "mqtt地址", key: "mqttUrl"},

    ]


    const [upgradeBatchId, setUpgradeBatchId] = useState(0);


    const throwVersionError = (err) => {
        enqueueSnackbar(err, {
            persist: false,
            variant: 'error',
        });
    }

    const [pendingBuzzerStatus, setPendingBuzzerStatus] = useState(0);

    const upgradeVersion = () => {
        upgrade({
            deviceSns: upgradeSns,
            batchId: upgradeBatchId
        }).catch(err => {
            enqueueSnackbar(err.message)
        })
    }

    const enterSearch = (e) => {
        if (e.keyCode === 13) {
            search(0, searchParams.pageSize)
        }
    }

    const [upgradeDialogBatchOpen, setUpgradeDialogBatchOpen] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    return (
        <Box>
            <Card variant={"outlined"} className={'p-3'}>
                <div>
                    <SearchHeader onClickSearch={() => {
                        search(0, searchParams.pageSize)
                    }}

                                  />
                </div>
                <Divider className={'pt-2'}/>

                <div className={'pt-3 flex gap-3 flex-wrap'}>
                    <TextField sx={{width: 220}} id="standard-basic" label="设备名称" variant={"outlined"}
                               size={"small"}
                               onChange={(evt) => {
                                   setSearchParams({
                                       ...searchParams,
                                       deviceName: evt.target.value
                                   })
                               }}
                               onKeyUp={e => enterSearch(e)}
                    />
                    <SelectBox isSearch nameTag={"productName"} valueTag={"id"} label={"产品类型"}
                               selectList={productTypeList}
                               handleChange={(value) => {
                                   setSearchParams({
                                       ...searchParams,
                                       productType: value
                                   })
                                   if (value !== '') {
                                       searchUpgradeBatch(value);
                                   }
                                   search(0, searchParams.pageSize)
                               }

                               }

                    />
                    <TextField sx={{width: 220}} id="standard-basic" label={"设备编号"} variant={"outlined"}
                               size={"small"}
                               onChange={(evt) => {
                                   setSearchParams({
                                       ...searchParams,
                                       deviceSn: evt.target.value
                                   })
                               }}
                               onKeyUp={e => enterSearch(e)}
                    />

                    <SelectBox nameTag={"name"} valueTag={"value"} selectList={[
                        {name: '离线', value: 0},
                        {name: '在线', value: 1},
                        {name: '升级中', value: 2},
                    ]} isSearch handleChange={(value) => {
                        setSearchParams({
                            ...searchParams,
                            status: value
                        })
                        search(0, searchParams.pageSize)

                    }} label={"状态"}/>

                    <TextField sx={{width: 220}} id="standard-basic" label={"物联网卡号"} variant={"outlined"}
                               size={"small"}
                               onChange={(evt) => {
                                   setSearchParams({
                                       ...searchParams,
                                       imsiNo: evt.target.value
                                   })
                               }}
                               onKeyUp={e => enterSearch(e)}
                    />


                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        sx={{width: 220}}
                        options={versionList}
                        value={searchParams.versionId}
                        renderInput={(params) => <TextField {...params} label="版本号"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                                delete searchParams.versionId
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    versionId: newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)

                        }}
                    />




                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        options={provinceList}
                        sx={{width: 220}}
                        renderInput={(params) => <TextField {...params} label="省"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                             delete searchParams.provinceCode
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    provinceCode: newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)

                        }}
                    />

                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        options={cityList}
                        sx={{width: 220}}
                        renderInput={(params) => <TextField {...params} label="市"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                                delete searchParams.cityCode
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    cityCode:  newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)

                        }}
                    />

                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        options={countyList}
                        sx={{width: 220}}
                        renderInput={(params) => <TextField {...params} label="区"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                                delete searchParams.countyCode
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    countyCode:  newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)

                        }}
                    />

                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        options={apartmentList}
                        sx={{width: 220}}
                        renderInput={(params) => <TextField {...params} label="小区"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                                delete searchParams.apartmentId
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    apartmentId: newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)
                        }}
                    />

                    <Autocomplete
                        size={"small"}
                        disablePortal
                        id="combo-box-demo"
                        options={ownerList}
                        sx={{width: 220}}
                        renderInput={(params) => <TextField {...params} label="拥有人"/>}
                        onChange={(evt, newValue) => {
                            if (newValue == null) {
                               delete searchParams.ownerId
                            } else {
                                setSearchParams({
                                    ...searchParams,
                                    ownerId:  newValue["value"]
                                })
                            }
                            search(0, searchParams.pageSize)
                        }}
                    />

                </div>

            </Card>

            <Card variant={"outlined"} style={{marginTop: 10}} className={"flex gap-2 p-3"}>

                <SelectBox isSearch nameTag={"name"} valueTag={"id"} selectList={upgradeBatches}
                           handleChange={value => {
                               setUpgradeBatchId(value)
                               setSearchParams({
                                   ...searchParams,
                                   batchId: upgradeBatchId
                               })
                           }}
                           label={"升级计划"}/>

                <Button onClick={() => {

                    console.log("设备编号:", checkedRows)
                    if (checkedRows == null || checkedRows.length === 0) {
                        checkUpgradeSns()
                        return
                    }
                    if (upgradeBatchId == null || upgradeBatchId === '') {
                        checkUpgradeVersion()
                        return
                    }
                    setUpgradeDialogBatchOpen(true);
                    setUpgradeSns(checkedRows.map(it => it.deviceSn));
                }} variant={"contained"}>批量升级</Button>

                <Dialog
                    open={upgradeDialogBatchOpen}
                    onClose={() => setUpgradeDialogBatchOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"确定升级"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            确定将当前的设备升级到
                            <Chip color={"primary"}
                                  label={upgradeBatches.filter(it => it.id === upgradeBatchId).length > 0
                                      ? upgradeBatches.filter(it => it.id === upgradeBatchId)[0].nextVersion : ''}/>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            upgradeVersion()
                            setUpgradeDialogBatchOpen(false)
                        }} autoFocus>
                            同意
                        </Button>
                        <Button onClick={() => setUpgradeDialogBatchOpen(false)}>取消</Button>
                    </DialogActions>
                </Dialog>
                <Divider/>
                <Button variant={"contained"} onClick={() => {
                    setConfigAppIdDialogOpen(true)
                    setUpgradeSns(checkedRows.map(it => it.deviceSn));
                }}>设置AppID</Button>
                <Dialog
                    open={configAppIdDialogOpen}
                    onClose={() => setConfigAppIdDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"设置appId"}
                    </DialogTitle>
                    <DialogContent>
                        <SelectBox selectList={productTypeList} nameTag={"productName"} valueTag={"appId"}
                                   handleChange={value => {
                                       setPendingAppId(value)
                                   }}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (checkedRows == null || checkedRows.length === 0) {
                                checkUpgradeSns()
                                return
                            }
                            sendConfigAppId({
                                deviceSns: upgradeSns,
                                appId: pendingAppId
                            }).catch(err => {
                                sendAlert(err)
                            })
                            setConfigAppIdDialogOpen(false)
                        }} autoFocus>
                            同意
                        </Button>
                        <Button onClick={() => setConfigAppIdDialogOpen(false)}>取消</Button>

                    </DialogActions>
                </Dialog>
                <Button variant={"contained"} onClick={() => {
                    setConfigBuzzerDialogOpen(true)
                    setUpgradeSns(checkedRows.map(it => it.deviceSn));
                }}>设置蜂鸣器状态</Button>
                <Dialog
                    open={configBuzzerDialogOpen}
                    onClose={() => setConfigBuzzerDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"设置蜂鸣器"}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">状态</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="controlled-radio-buttons-group"
                                value={pendingBuzzerStatus}
                                onChange={(evt) => {
                                    setPendingBuzzerStatus(evt.target.value)
                                }}
                            >
                                <FormControlLabel value="0" control={<Radio/>} label="关闭"/>
                                <FormControlLabel value="1" control={<Radio/>} label="开启"/>
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (checkedRows == null || checkedRows.length === 0) {
                                checkUpgradeSns()
                                return
                            }
                            sendConfigBuzzer({
                                deviceSns: upgradeSns,
                                status: pendingBuzzerStatus
                            }).catch(err => {
                                sendAlert(err)
                            })
                            setConfigBuzzerDialogOpen(false)
                        }} autoFocus>
                            同意
                        </Button>
                        <Button onClick={() => setConfigBuzzerDialogOpen(false)}>取消</Button>

                    </DialogActions>
                </Dialog>
                <Button variant={"contained"} onClick={() => {
                    setRestartDialogOpen(true)
                    setUpgradeSns(checkedRows.map(it => it.deviceSn));
                }}>重启</Button>
                <Dialog
                    open={restartDialogOpen}
                    onClose={() => setRestartDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"重启"}
                    </DialogTitle>
                    <DialogContent>
                        <Stack>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">是否强制重启</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="gender"
                                    name="controlled-radio-buttons-group"
                                    value={rebootProps.isForceReboot}
                                    onChange={(evt) => {
                                        setRebootProps({
                                            ...rebootProps,
                                            isForceReboot: evt.target.value
                                        })
                                    }}
                                >
                                    <FormControlLabel value="0" control={<Radio/>} label="否"/>
                                    <FormControlLabel value="1" control={<Radio/>} label="是"/>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">是否回滚版本</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="gender"
                                    name="controlled-radio-buttons-group"
                                    value={rebootProps.isGoBackVersion}
                                    onChange={(evt) => {
                                        setRebootProps({
                                            ...rebootProps,
                                            isGoBackVersion: evt.target.value
                                        })
                                    }}
                                >
                                    <FormControlLabel value="0" control={<Radio/>} label="否"/>
                                    <FormControlLabel value="1" control={<Radio/>} label="是"/>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (checkedRows == null || checkedRows.length === 0) {
                                checkUpgradeSns()
                                return
                            }
                            sendReboots({
                                deviceSns: upgradeSns,
                                isForceReboot: rebootProps.isForceReboot,
                                isGoBackVersion: rebootProps.isGoBackVersion
                            }).catch(err => {
                                sendAlert(err)
                            })
                            setRestartDialogOpen(false)
                        }} autoFocus>
                            同意
                        </Button>
                        <Button onClick={() => setRestartDialogOpen(false)}>取消</Button>

                    </DialogActions>
                </Dialog>

                <Button variant={"contained"} onClick={() => {
                    setMqttUrlDialogOpen(true)
                    setUpgradeSns(checkedRows.map(it => it.deviceSn));
                }}>设置MQTT地址</Button>
                <Dialog
                    open={mqttUrlDialogOpen}
                    onClose={() => setMqttUrlDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"设置MQTT地址"}
                    </DialogTitle>
                    <DialogContent>
                        <SelectBox selectList={mqttUrlTypeList} nameTag={"remark"} valueTag={"mqttUrl"}
                                   handleChange={value => {
                                       setPendingMqttUrl(value)
                                   }}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (checkedRows == null || checkedRows.length === 0) {
                                checkUpgradeSns()
                                return
                            }
                            sendMqttUrl({
                                deviceSns: upgradeSns,
                                mqttUrl: pendingMqttUrl
                            }).then(() => {
                                // 请求成功时设置提示信息并打开 Snackbar
                                setSnackbarMessage('MQTT URL 更新成功！');
                                setSnackbarOpen(true);
                            }).catch(err => {
                                sendAlert(err)
                            })
                            setMqttUrlDialogOpen(false)
                        }} autoFocus>
                            同意
                        </Button>
                        <Button onClick={() => setMqttUrlDialogOpen(false)}>取消</Button>

                    </DialogActions>
                </Dialog>


            </Card>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Box style={{marginTop: 10}}>
                <CTable
                    headRow={headRow}
                        hasCheckBox={true}
                        handleCheckBoxSelect={(rows) => {
                            console.log('选中的行', rows)
                            setCheckedRows(rows)
                        }}
                        dataRows={pageData.dataList}
                        isPagination
                        totalCount={pageData.totalCount}
                        rowsPerPage={searchParams.pageSize}
                        page={searchParams.pageNum}
                        onPageChange={(evt, page) => {
                            console.log(page)
                            setSearchParams({
                                ...searchParams,
                                pageNum: page
                            })

                            search(page + 1, searchParams.pageSize)
                        }}
                        onRowsPerPageChange={(evt) => {
                            setSearchParams({
                                ...searchParams,
                                pageSize: evt.target.value,
                                pageNum: 0
                            })
                            search(searchParams.pageNum, evt.target.value)
                        }}
                />
            </Box>


            <Dialog open={saveDialogOpen}
                    onClose={handleSaveDialogClose}>
                <DialogTitle>新增</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <Stack spacing={3}>
                            <TextField id="standard-basic" label="设备名称" variant={"outlined"} size={"small"}
                                       onChange={(evt) => {
                                           setSaveDeviceObj({
                                               ...saveDeviceObj,
                                               productName: evt.target.value
                                           })
                                       }}/>
                            <SelectBox label={"产品类型"} handleChange={(value) => {
                                setSaveDeviceObj({
                                    ...saveDeviceObj,
                                    type: value
                                })
                            }} selectList={[{name: '2路', value: 1}]}/>

                            <TextField id="standard-basic" label="备注" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveDeviceObj({
                                               ...saveDeviceObj,
                                               note: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="业务协议" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveDeviceObj({
                                               ...saveDeviceObj,
                                               bizProtocol: evt.target.value
                                           })
                                       }}/>
                            <TextField id="standard-basic" label="通讯协议" variant={"outlined"} size={"small"}
                                       onChange={evt => {
                                           setSaveDeviceObj({
                                               ...saveDeviceObj,
                                               techProtocol: evt.target.value
                                           })
                                       }}/>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={handleSaveDialogClose}>取消</Button>
                    <Button variant={"outlined"} color="primary" onClick={() => {
                    }}>确定</Button>
                </DialogActions>
            </Dialog>


        </Box>
    )
}



