import {api_header, get, post} from "../utils/HttpClient";

export function listFirmware(productId) {
    return get(`${api_header}/firmware/list/${productId}`)
}

export function addFirmware(params) {
    return post(`${api_header}/firmware/add`, params)
}

export function editFirmware(params) {
    return post(`${api_header}/firmware/update`, params)
}

export function delFirmware(id) {
    return post(`${api_header}/firmware/del/${id}`)
}

export function listVersion(fwId) {
    return get(`${api_header}/firmware/version/list/${fwId}`)
}

export function deleteVersion(id) {
    return post(`${api_header}/firmware/version/del/${id}`)
}


export function pageFirmwareVersion(searchParams) {
    return get(`${api_header}/firmware/version/page`, searchParams)
}


export function addFirmwareVersion(params) {
    return post(`${api_header}/firmware/version/add`, params);
}


export function editFirmwareVersion(params) {
    return post(`${api_header}/firmware/version/update`, params)
}
