import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import PropTypes from 'prop-types';

/**
 *
 * {headRow: field, name, tag}
 *
 */


CTable.propTypes = {
    headRow: PropTypes.array,
    dataRows: PropTypes.array,
    page: PropTypes.number,
    totalCount: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    rowsPerPageOptions: PropTypes.array,
    toolbar: PropTypes.func,
    isPagination: PropTypes.bool
}


export default function CTable(props) {

    const {
        headRow,
        dataRows,
        page,
        totalCount,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        toolbar,
        isPagination,
        hasCheckBox,
        handleCheckBoxSelect
    } = props;


    let isPaginationCopy = isPagination || false;

    const [totalCheck, setTotalCheck] = useState(false);

    const [checkArray, setCheckArray] = useState([])

    const [checkedRows, setCheckedRows] = useState([]);


    useEffect(() => {
        setTotalCheck(false)
        setCheckArray([])
        setCheckedRows([])
    }, [dataRows])

    return (
        <Paper variant={"outlined"}>
            <TableContainer>
                {toolbar != null &&
                <Box style={{paddingLeft: 15, paddingTop: 10, paddingBottom: 10}}>
                    {toolbar()}
                </Box>
                }
                {toolbar != null && <Divider/>}
                <TablePagination
                    showLastButton={true}
                    showFirstButton={true}
                    rowsPerPageOptions={rowsPerPageOptions == null ? [1, 10, 50, 100,500, 1000] : rowsPerPageOptions}
                    component="div"
                    count={totalCount == null ? 0 : totalCount}
                    rowsPerPage={rowsPerPage == null ? 100 : rowsPerPage}
                    page={page == null ? 0 : page}
                    onPageChange={onPageChange != null && onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange != null && onRowsPerPageChange}
                />
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                hasCheckBox != null && hasCheckBox === true &&
                                <TableCell>
                                    <Checkbox checked={totalCheck}
                                              onChange={(evt) => {
                                                  setTotalCheck(evt.target.checked)
                                                  if (evt.target.checked) {
                                                      handleCheckBoxSelect(dataRows)
                                                      setCheckArray(dataRows.map(it => it.id))
                                                      setCheckedRows(dataRows)
                                                  } else {
                                                      handleCheckBoxSelect([])
                                                      setCheckedRows([])
                                                      setCheckArray([])
                                                  }
                                              }
                                              }/></TableCell>
                            }
                            {headRow != null && headRow.map(it =>
                                <TableCell>{it.name}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataRows != null && dataRows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >

                                {
                                    hasCheckBox != null && hasCheckBox === true && headRow != null &&
                                    <TableCell>

                                        <Checkbox checked={checkArray.indexOf(row.id) > -1} onChange={(evt) => {
                                            let array = checkArray.slice();
                                            let ind = array.indexOf(row.id)
                                            if (ind > -1) {
                                                array.splice(ind, 1)
                                            } else {
                                                array.push(row.id)
                                            }
                                            setCheckArray(array)

                                            let row1 = checkedRows;
                                            if (evt.target.checked === true) {
                                                row1.push(row)
                                            } else {
                                                console.log("删除", row1)
                                                row1 = row1.filter(it => it.id !== row.id)
                                                console.log(row1)
                                            }
                                            setCheckedRows(row1)
                                            handleCheckBoxSelect(row1)
                                        }
                                        }/>
                                    </TableCell>
                                }
                                {headRow != null && headRow.map(head => {
                                    if (head.render == null) {
                                        return row[head.key] !== undefined &&
                                            <TableCell>
                                                {row[head.key]}
                                            </TableCell>;
                                    } else {
                                        return <TableCell>{head.render(row)}</TableCell>
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                isPaginationCopy && <Divider/>
            }
            {isPaginationCopy &&
            <TablePagination
                showLastButton={true}
                showFirstButton={true}
                rowsPerPageOptions={rowsPerPageOptions == null ? [1, 10, 50, 100,500, 1000] : rowsPerPageOptions}
                component="div"
                count={totalCount == null ? 0 : totalCount}
                rowsPerPage={rowsPerPage == null ? 100 : rowsPerPage}
                page={page == null ? 0 : page}
                onPageChange={onPageChange != null && onPageChange}
                onRowsPerPageChange={onRowsPerPageChange != null && onRowsPerPageChange}
            />
            }

        </Paper>
    )


}
