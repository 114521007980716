import {api_header,get} from "../utils/HttpClient";

export function listProducts(params = {}) {
    return get(`${api_header}/products/list`, params);
}


export function pageProducts(params) {
    return get(`${api_header}/products/page`, params)
}

export function save() {

}

export function del() {

}

export function findOne() {

}
