import {api_header, get, post} from "../utils/HttpClient";

export  function pageRepairLog(searchParams={}) {
    return get(`${api_header}/repairLog/page`, searchParams)
}


export  function addRepairLog(searchParams={}) {
    return post(`${api_header}/repairLog/add`, searchParams)
}


export function listFaultType() {
    return get(`${api_header}/faultType/list`)
}

