import {post, get, api_header} from '../utils/HttpClient'


export function addPlan(params) {
    return post(`${api_header}/upgrade/plan/add`, params)
}

export function pagePlan(params) {
    return get(`${api_header}/upgrade/plan/page`, params)
}


export function upgrade(params) {
    return post(`${api_header}/firmware/upgrade`, params)
}




export function pageUpgradeLog(params) {
    return get(`${api_header}/upgrade/log`, params)
}



export function pageUpgradeBatch(params) {
    return get(`${api_header}/upgrade/batch/page`, params)
}

export function addUpgradeBatch(params) {
    return post(`${api_header}/upgrade/batch/add`, params)
}

export function listUpgradeBatch(params) {
    return get(`${api_header}/upgrade/batch/list`, params);
}

export function deleteUpdate(id) {
    return post(`${api_header}/upgrade/plan/delete/${id}`);
}
