import React, {useEffect, useState} from "react";
import {Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import SelectBox from "./SelectBox";


MultiSelectBox.propTypes = {
    size: PropTypes.string,
    selectList: PropTypes.array,
    handleChange: PropTypes.func,
    label: PropTypes.string,
    isSearch: PropTypes.bool,
    nameTag: PropTypes.string,
    valueTag: PropTypes.string,
    originValues: PropTypes.string,
}

export default function MultiSelectBox(props) {

    const [boxValues, setBoxValues] = useState([]);

    const {
        size,
        selectList,
        handleChange,
        label,
        isSearch,
        nameTag,
        valueTag,
        originValues
    } = props;

    let isSearchCopy = isSearch || false;

    if (originValues != null ) {
        console.log("存在预设值", originValues)
        setBoxValues(originValues)
    }

    const handleInnerChange = (event) => {

        let value = event.target.value
        console.log('刺激', value)
        setBoxValues(typeof value === 'string' ? value.split(',') : value)
        handleChange(value)
    }

    useEffect(() => {
        if (originValues != null) {
            setBoxValues(originValues)
        }
    }, [])


    return (
        <FormControl fullWidth maxWidth={"lg"} sx={{m: 1, width: 220}} size={size == null ? "small" : size}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={boxValues}
                label={label}
                multiple
                onChange={handleInnerChange}
                input={<OutlinedInput label="Name"/>}
                renderValue={(selected) =>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {
                            selected.map(it =>
                                selectList
                                    .filter(itt => itt[valueTag] === it)[0][nameTag]).map(it => <Chip key={it}
                                                                                                      label={it}/>)
                        }
                    </Box>}
            >
                {isSearchCopy && <MenuItem value={''}>全部</MenuItem>}
                {selectList != null && selectList.map(it =>
                    <MenuItem key={it[valueTag]} value={it[valueTag]}>
                        <Checkbox checked={boxValues.indexOf(it[valueTag]) > -1}/>
                        <ListItemText primary={it[nameTag]}/>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
