// import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link as RouterLink, Route, Switch} from 'react-router-dom';
import Dashboard from "../pages/Dashboard";
import Device from "../pages/Device";
import {Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField} from "@mui/material";
import Product from "../pages/Product";
import EvStationIcon from '@mui/icons-material/EvStation';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BackupIcon from '@mui/icons-material/Backup';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeviceDetail from "../pages/DeviceDetail";
import Firmware from "../pages/Firmware";
import FirmwareVersion from "../pages/FirmwareVersion";
import Upgrade from '../pages/Upgrade';
import UpgradeBatch from '../pages/UpgradeBatch';
import FaultData from "../pages/FaultData";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import RepairData from "../pages/RepairData";
import Button from "@mui/material/Button";
import {useState} from "react";
import {login} from "../request/LoginRequest";
import {useSnackbar} from "notistack";
import DeviceMap from "../pages/DeviceMap";
import RoomIcon from '@mui/icons-material/Room';
import TestDevice from "../pages/Test/TestDevice";
import TestFirmware from "../pages/Test/TestFirmware";
import TestDeviceDetail from "../pages/Test/TestDeviceDetail";
import {getWithExpiry, setWithExpiry} from "../utils/StorageUtil";

const drawerWidth = 240;


export default function GlobalLayout() {

    const [logInOpen, setLoginOpen] = useState(false);

    const [username, setUsername] = useState('');

    const [password, setPassword] = useState("");

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const alert = (data) => {
        enqueueSnackbar(data, {
            persist: false,
            variant: 'error',
        });
    };


    let storage = localStorage

    console.log("用户名称:", storage.getItem("username"))


    const submit = () => {

        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        login(formData).then(data => {
            setLoginOpen(false)
            setWithExpiry("username", username, 24 * 60 * 1000)
            window.location.reload()
            console.log(storage.getItem("username"))

        }).catch(data => {
            alert(data.message)
        })
    }


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        安充IOT管理平台
                    </Typography>

                    {
                       getWithExpiry("username") == null ?
                            <Button color="inherit"
                                    onClick={() => setLoginOpen(true)}>登录</Button> : getWithExpiry("username")
                    }
                    <Dialog open={logInOpen} onClose={() => {
                        setLoginOpen(false)
                    }}>
                        <DialogTitle>登录</DialogTitle>
                        <DialogContent>

                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="用户名"
                                fullWidth
                                variant="standard"
                                onChange={evt => {
                                    setUsername(evt.target.value)
                                }}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="密码"
                                type={"password"}
                                fullWidth
                                variant="standard"
                                onChange={evt => {
                                    setPassword(evt.target.value)
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setLoginOpen(false)}>取消</Button>
                            <Button onClick={() => submit()}>登录</Button>
                        </DialogActions>
                    </Dialog>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}
            >
                <Toolbar/>
                <Box sx={{overflow: 'auto'}}>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/dashboard"}>
                            <ListItem button key={'dashboard'}>
                                <ListItemIcon>
                                    <DashboardIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'控制台'}/>
                            </ListItem>
                        </LinkRouter>
                    </List>
                    <Divider/>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/product"}>
                            <ListItem button key={'product'}>
                                <ListItemIcon>
                                    <PanoramaWideAngleIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'产品管理'}/>
                            </ListItem>
                        </LinkRouter>
                        <LinkRouter underline="hover" color="inherit" to={"/device"}>
                            <ListItem button key={'device'}>
                                <ListItemIcon>
                                    <EvStationIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'设备管理'}/>
                            </ListItem>
                        </LinkRouter>


                    </List>
                    <Divider/>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/firmware"}>
                            <ListItem button key={'firmware'}>
                                <ListItemIcon>
                                    <BackupIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'固件管理'}/>
                            </ListItem>
                        </LinkRouter>

                        <LinkRouter underline="hover" color="inherit" to={"/upgrade"}>
                            <ListItem button key={'upgrade'}>
                                <ListItemIcon>
                                    <UpgradeIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'升级管理'}/>
                            </ListItem>
                        </LinkRouter>

                    </List>
                    <Divider/>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/faultData"}>
                            <ListItem button key={'faultData'}>
                                <ListItemIcon>
                                    <ErrorOutlineIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'故障管理'}/>
                            </ListItem>
                        </LinkRouter>
                    </List>
                    {/*<List>*/}
                    {/*    <LinkRouter underline="hover" color="inherit" to={"/repairData"}>*/}
                    {/*        <ListItem button key={'repairData'}>*/}
                    {/*            <ListItemIcon>*/}
                    {/*                <HomeRepairServiceIcon/>*/}
                    {/*            </ListItemIcon>*/}
                    {/*            <ListItemText primary={'维修记录'}/>*/}
                    {/*        </ListItem>*/}
                    {/*    </LinkRouter>*/}
                    {/*</List>*/}
                    <Divider/>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/testDevice"}>
                            <ListItem button key={'testDevice'}>
                                <ListItemIcon>
                                    <ErrorOutlineIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'测试设备'}/>
                            </ListItem>
                        </LinkRouter>
                    </List>
                    <List>
                        <LinkRouter underline="hover" color="inherit" to={"/testFw"}>
                            <ListItem button key={'testFw'}>
                                <ListItemIcon>
                                    <HomeRepairServiceIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'测试固件'}/>
                            </ListItem>
                        </LinkRouter>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <Toolbar/>
                <Switch>
                    <Route exact path="/">
                        <Dashboard/>
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard/>
                    </Route>
                    <Route exact path="/product">
                        <Product/>
                    </Route>
                    <Route exact path="/device">
                        <Device/>
                    </Route>
                    <Route exact path="/deviceMap">
                        <DeviceMap/>
                    </Route>
                    <Route exact path="/firmware">
                        <Firmware/>
                    </Route>
                    <Route exact path="/upgrade">
                        <Upgrade/>
                    </Route>
                    <Route exact path="/firmware/version/:id">
                        <FirmwareVersion/>
                    </Route>
                    <Route exact path="/device/detail/:sn" children={<DeviceDetail/>}>
                    </Route>
                    <Route exact path="/test/device/detail/:sn" children={<TestDeviceDetail/>}>
                    </Route>
                    <Route exact path="/upgrade/plan/sub/:id">
                        <UpgradeBatch/>
                    </Route>
                    <Route exact path="/faultData">
                        <FaultData/>
                    </Route>
                    <Route exact path="/repairData">
                        <RepairData/>
                    </Route>
                    <Route exact path="/testDevice">
                        <TestDevice/>
                    </Route>
                    <Route exact path="/testFw">
                        <TestFirmware/>
                    </Route>
                </Switch>
            </Box>
        </Box>
    );
}

const LinkRouter = (props) => {

    return <Link {...props} component={RouterLink}/>
}
